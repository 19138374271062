import React, { useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import LanguageContext from "./utils/LanguageContext";
import InterviewWizard from './components/Interview/Wizard';
import MediaInquiriesWizard from './components/MediaInquiries/Wizard';
import MailingListWizard from './components/MailingList/Wizard';
import MultimediaWizard from './components/Multimedia/Wizard';
import VisitWizard from './components/Visit/Wizard';
import PhotographyWizard from './components/Photography/Wizard';
import QatarizationWizard from './components/Qatarization/Wizard';
import MediaToursWizard from './components/MediaTours/Wizard';
import { IntlProvider } from "react-intl";
import Helmet from "react-helmet";

import locale_en from "./data/messages/en.json";
import locale_ar from "./data/messages/ar.json";

const data = {
  'ar': locale_ar,
  'en': locale_en
};

function App() {
  const [language, setLanguage] = useState("en");
  const value = { language, setLanguage };

  if(language === 'ar') {
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.getElementsByTagName('html')[0].setAttribute("lang", "ar");
  } else {
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.getElementsByTagName('html')[0].setAttribute("lang", "en");
  }
  
  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={language} messages={data[language]}>
        <HashRouter> 
          <Switch>
            <Route path='/balagh' component={() => { 
                window.location.href = 'https://app.convercent.com/en-us/LandingPage/6a20df26-f60f-ec11-a981-000d3ab9f296'; 
                return null;
            }}/>
            <Route exact path="/interview">
              <Helmet title="Interview Requests" />
              <InterviewWizard currLang={language} />
            </Route>
            <Route exact path="/mediainquiries">
              <Helmet title="Media Inquiries" />
              <MediaInquiriesWizard currLang={language} />
            </Route>
            <Route exact path="/mailinglist">
              <Helmet title="QF Mailing List" />
              <MailingListWizard currLang={language} />
            </Route>
            <Route exact path="/multimedia">
              <Helmet title="Multimedia Request" />
              <MultimediaWizard currLang={language} />
            </Route>
            <Route exact path="/photography">
              <Helmet title="Filming and Photography Requests" />
              <PhotographyWizard currLang={language} />
            </Route>
            <Route exact path="/qatarization">
              <Helmet title="Qatarization Form" />
              <QatarizationWizard currLang={language} />
            </Route>
            <Route exact path="/mediatours">
              <Helmet title="Media Tours" />
              <MediaToursWizard currLang={language} />
            </Route>
            <Route exact path='/visit' component={() => { window.location = 'https://www.qf.org.qa/media-center#section-1'; return null;} }/>
            <Route exact path='/' component={() => { window.location = 'https://www.qf.org.qa/media-center#section-1'; return null;} }/>
          </Switch>
        </HashRouter>  
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export default App;
