import React, { useState, useEffect } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core'
import Review from '../Review'
import SelectField, { InputField, TextAreaField, TileField, DatePickerField } from '../Fields'
import { ProgressIndicator, ProgressStep, Grid, Row, Column, FormLabel, Button, Search } from "carbon-components-react";
import { Add16, Delete16 } from '@carbon/icons-react';
// @ts-ignore
import { COUNTRIES_EN, COUNTRIES_AR } from '../../data/Countries'
import { socialmedia, socialmediaAR, timeLong, timeLongAR } from '../../data/Options'
import { isEmail, isNumber, isMaxLength } from '@formiz/validations'
import { injectIntl } from 'react-intl';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import { v4 as uuidv4 } from 'uuid';

const defaultCollection = [
  {
    id: uuidv4(),
    name: '',
  },
];

const defaultSMCollection = [
  {
    id: uuidv4(),
    name: '',
  },
];

const defaultDateCollection = [
  {
    id: uuidv4(),
    name: '',
  },
];

/* @ts-ignore */
const MultimediaWizard = (props: { currLang: any, intl: any }) => {
  const qfForm = useForm()
  const [collection, setCollection] = useState(defaultCollection);
  const [smcollection, setSmcollection] = useState(defaultSMCollection);
  const [dateCollection, setDateCollection] = useState(defaultDateCollection);
  const [prefLanguage, setPrefLanguage] = useState(true);
  const [prefMedia, setPrefMedia] = useState(true);
  const [isTraditionalMedia, setIsTraditionalMedia] = useState(false);
  const [isSocialMedia, setIsSocialMedia] = useState(false);
  const [prefTopic, setPrefTopic] = useState(true);
  const [locationRequired, setLocationRequired] = useState(true);
  const [isLocationValid, setIsLocationValid] = useState(true);

  var traditional_media_collection: any = [];
  var social_media_collection: any = [];
  var datetimeCollection: any = [];
  var participants: any = [];

  useEffect(() => {
    setIsTraditionalMedia(false);
    setIsSocialMedia(false);
    setCollection(defaultCollection);
    setSmcollection(defaultSMCollection);
    setDateCollection(defaultDateCollection);
  }, [qfForm.resetKey]);

  const addItem = () => {
    setCollection((c) => [
      ...c,
      {
        id: uuidv4(),
        name: ''
      },
    ]);
  };

  /* @ts-ignore */
  const removeItem = (id) => {
    setCollection((c) => c.filter((x) => x.id !== id));
  };

  const addSMItem = () => {
    setSmcollection((c) => [
      ...c,
      {
        id: uuidv4(),
        name: ''
      },
    ]);
  };

  /* @ts-ignore */
  const removeSmItem = (id) => {
    setSmcollection((c) => c.filter((x) => x.id !== id));
  };

  const addDateItem = () => {
    setDateCollection((c) => [
      ...c,
      {
        id: uuidv4(),
        name: ''
      },
    ]);
  };

  /* @ts-ignore */
  const removeDateItem = (id) => {
    setDateCollection((c) => c.filter((x) => x.id !== id));
  };


  /* @ts-ignore */
  const handleChange = (event) => {
      // Declare variables
      var input, filter, list, location, a, i, txtValue;
      filter = event.target.value && event.target.value.toUpperCase();
      list = document.getElementById("locations");
      // @ts-ignore
      location = list.getElementsByClassName('location');

      // Loop through all list items, and hide those who don't match the search query
      for (i = 0; i < location.length; i++) {
        a = location[i].getElementsByClassName("bx--tile-content")[0];
        // @ts-ignore
        txtValue = a.textContent || a.innerText;

        if(!event.target.value) {
          // @ts-ignore
          location[i].style.display = "block";
        } else {
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            // @ts-ignore
            location[i].style.display = "block";
          } else {
            // @ts-ignore
            location[i].style.display = "none";
          }
        }
      }
  }

  const LocationsFilter = () => {
    return (
      <div className="bx--row">
        <div className="bx--col-sm-4 padding-bottom-lg">
          <Search
            id={'filter'}
            labelText={''}
            className="minwidth-0 no-mgn-btm"
            onChange={e => handleChange(e)}
            placeHolderText={props.intl.formatMessage({ id: "field.search_locations" })}>
          </Search>
        </div>
      </div>
    )
  }

  // @ts-ignore
  const setLocation = (e) => {
    var choices = [];
    var els = document.getElementsByName('confirm_location');
    for (var i = 0; i < els.length; i++){
      // @ts-ignore
      if ( els[i].checked ) {
        // @ts-ignore
        choices.push(els[i].value);
      }
    }
    if(choices.length >= 1) {
      setLocationRequired(false)
    } else {
      setLocationRequired(true)
    }
  }


  var radioboxes = []
  var allRadioboxes = document.querySelectorAll('input[type=radio]:checked')

  for (var n = 0; n < allRadioboxes.length; n++) {
    /* @ts-ignore */
    radioboxes.push({[allRadioboxes[n].name]: allRadioboxes[n].value})
  }

  var step1 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step1')).sort()
          .map(([_, v]) => v)

  var step2 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step2')).sort()
          .map(([_, v]) => v)

  var step3 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step3')).sort()
          .map(([_, v]) => v)        

  var step4 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step4')).sort()
          .map(([_, v]) => v)

  var step5 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step5')).sort()
          .map(([_, v]) => v)
          
  var step6 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step6')).sort()
          .map(([_, v]) => v)         

  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step4' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.collection && field.collection
          // @ts-ignore
          items && items.forEach((item, i) => {
            // @ts-ignore
            datetimeCollection.push(`<br>${props.intl.formatMessage({ id: "field.date" })} #${i + 1}:`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                datetimeCollection.push(`${n === (Object.keys(item).length - 1) ? `\n` : ''}${ n == 0 ? `<br>` + props.intl.formatMessage({ id: `field.from` }) + ': ' : n === 2 ? ' ' + props.intl.formatMessage({ id: `field.to` }) + ': ' : '' }${n === (Object.keys(item).length - 1) ? `\n` : ''}${ k === '1_from_time' || k === '3_to_time' ? item[k] && ' ' + item[k] : ' ' + item[k] }`)
            })
          })
      });
  });

  // @ts-ignore
  var datetimeCollections = datetimeCollection.join(",").split(",").join("\n").replace(/(\r\n|\n|\r)/gm, "").replace(/<br>/g, '\n');

  // @ts-ignore
  var selected_country = step1[0] && step1[0]['6_country']
  // @ts-ignore
  step1[0] && delete step1[0]['6_country']

  // @ts-ignore
  step2[0] && delete step2[0]['0_photography'] && delete step2[0]['0_filming'] && delete step2[0]['1_inside_buildings'] && delete step2[0]['1_outside_buildings']

  // @ts-ignore
  step3[0] && delete step3[0]['0_traditional_media'] && delete step3[0]['0_socialmedia']

  // @ts-ignore
  step3[0] && delete step3[0]['0_traditional_media'] && delete step3[0]['0_socialmedia']

  // @ts-ignore
  step5[0] && 
    // @ts-ignore
    delete step5[0]['1_location_option1'] && 
    // @ts-ignore
    delete step5[0]['1_location_option2'] &&
    // @ts-ignore
    delete step5[0]['1_location_option3'] &&
    // @ts-ignore
    delete step5[0]['1_location_option4'] &&
    // @ts-ignore
    delete step5[0]['1_location_option5'] &&
    // @ts-ignore
    delete step5[0]['1_location_option6'] && 
    // @ts-ignore
    delete step5[0]['1_location_option7'] &&
    // @ts-ignore
    delete step5[0]['1_location_option8'] &&
    // @ts-ignore
    delete step5[0]['1_location_option9'] &&
    // @ts-ignore
    delete step5[0]['1_location_option10'] &&
    // @ts-ignore
    delete step5[0]['1_location_option11'] && 
    // @ts-ignore
    delete step5[0]['1_location_option12'] &&
    // @ts-ignore
    delete step5[0]['1_location_option13'] &&
    // @ts-ignore
    delete step5[0]['1_location_option14'] && 
    // @ts-ignore
    delete step5[0]['1_location_option17']

  var checkedValue = []; 
  var inputElements = document.getElementsByName('nature_of_request');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedValue.push(inputElements[i].value);
      }
  }

  var film_or_photograph_location_value = []; 
  var inputElements = document.getElementsByName('film_or_photograph_location');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          film_or_photograph_location_value.push(inputElements[i].value);
      }
  }

  var publishLocationValue = []; 
  var inputElements = document.getElementsByName('publish_location');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          publishLocationValue.push(inputElements[i].value);
      }
  }

  var checkedLocations = []; 
  var inputElements = document.getElementsByName('confirm_location');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedLocations.push(inputElements[i].value);
      }
  }

  // @ts-ignore
  step1 = step1[0]
  // @ts-ignore
  step2 = step2[0]
  // @ts-ignore
  step3 = step3[0]
  // @ts-ignore
  step5 = step5[0]
  // @ts-ignore
  step6 = step6[0]

  // @ts-ignore
  step1 = {...step1, ['6_country']: selected_country };
  // @ts-ignore
  step2 = {...step2, ['0_nature_of_request']: checkedValue.join(', '), ['1_film_or_photograph_location']: film_or_photograph_location_value.join(', ')};
  // @ts-ignore
  step3 = {...step3, ['0_publish_location']: publishLocationValue.join(', ')};
  // @ts-ignore
  step4 = {['0_selected_date']: datetimeCollections};
  // @ts-ignore
  step5 = {...step5, ['0_confirm_location']: checkedLocations.join(', \n')};
  
  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step3' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.collection && field.collection
          // @ts-ignore
          items && items.forEach((item, i) => {
            traditional_media_collection.push(`Name of the publication/broadcast outlet: #${i + 1}`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                traditional_media_collection.push(` ${ item[k] }`)
                n === (Object.keys(item).length - 1) && traditional_media_collection.push(`\n`)
            })
          })
      });
  });

  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step3' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.smcollection && field.smcollection
          // @ts-ignore
          items && items.forEach((item, i) => {
            social_media_collection.push(`Platform: #${i + 1}`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                social_media_collection.push(`${ item[k] }`)
                n === (Object.keys(item).length - 1) && social_media_collection.push(`\n`)
            })
          })
      });
  });

  var traditional_media_collections = traditional_media_collection.join(",").split(",").join("\n")
  var social_media_collections = social_media_collection.join(",").split(",").join("\n")

  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step6' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.collection && field.collection
          // @ts-ignore
          items && items.forEach((item, i) => {
            participants.push(`Participant: #${i + 1}`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                participants.push(`${props.intl.formatMessage({ id: `field.${k.slice(2)}` })}: ${ item[k] }`)
                n === (Object.keys(item).length - 1) && participants.push(`\n`)
            })
          })
      });
  });

  participants = participants.join(",").split(",").join("\n")

  let formData = {
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    traditional_media_collections,
    social_media_collections,
    participants
  }

  /* @ts-ignore */
  const switchStep = (element, step) => {
    element.preventDefault();
    qfForm.goToStep(step.name)
  }

  const preferredLanguage = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('nature_of_request');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefLanguage(false)
    } else {
      setPrefLanguage(true)
    }
  };

  const setPublishLocation = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('publish_location');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefMedia(false)
    } else {
      setPrefMedia(true)
    }
  };

  useEffect(() => {
    var choices = [];
    var els = document.getElementsByName('confirm_location');
    for (var i = 0; i < els.length; i++){
      // @ts-ignore
      if ( els[i].checked ) {
        // @ts-ignore
        choices.push(els[i].value);
      }
    }

    if(choices.length >= 1) {
      setIsLocationValid(true)
    } else {
      if(!qfForm.isStepSubmitted) {
        setIsLocationValid(true)
      } else {
        setIsLocationValid(false)
      }
    }
  });

  return (
    <Formiz
      connect={qfForm}
      onValidSubmit={null}
    >
      <Header currentForm={qfForm} currLang={props.currLang} />
      <form
        noValidate
        onSubmit={qfForm.submitStep}
        className={'wizard photography'}
      >
        <Grid>
          {
            !qfForm.isLastStep && (
              <Row>
              <Column>
                <FormLabel>
                  <h2>
                    { props.intl.formatMessage({ id: "photography.title" }) }
                  </h2>
                </FormLabel>
              </Column>
            </Row>
            )
          }
          {!qfForm.isFirstStep && !qfForm.isLastStep && (
          <Row>
            <Column lg={{ span: 12, offset: 0 }}>
              {
                /* @ts-ignore */
                <ProgressIndicator spaceEqually={true}>
                  <div className="form__tabs">
                  {
                    /* @ts-ignore */
                    qfForm.steps.map((step, i) => (
                      i !== 0 && i !== (qfForm.steps.length - 1) && (
                        /* @ts-ignore */
                        <ProgressStep
                          onClick={(element) => switchStep(element, step)} 
                          label={step.label} 
                          key={step.name}
                          /* @ts-ignore */
                          current={step.name === qfForm.currentStep.name ? true: false} 
                          complete={step.isValid && step.isSubmitted ? true: false} 
                          disabled={ i === (qfForm.steps.length - 2) && !qfForm.isValid ? true : false }
                        />
                      )
                    ))
                    }
                  </div>
                </ProgressIndicator>
              }
            </Column>
          </Row>
          )}
          </Grid>
          {
            /* @ts-ignore */
            <FormizStep
              name="photography.step0"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <p>
                        {
                          props.intl.formatMessage({ id: "photography.intro_text" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
            name="photography.step1"
            label={ props.intl.formatMessage({ id: "form.steps.details" }) }
          >
            <Grid className='margin-md form'>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.1_name"
                  label={ props.intl.formatMessage({ id: "field.name" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.2_mobile"
                  label={ props.intl.formatMessage({ id: "field.mobile" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isNumber(),
                      message: props.intl.formatMessage({ id: "validation.mobile" }),
                    },
                    {
                      rule: isMaxLength(12),
                      message: props.intl.formatMessage({ id: "validation.mobile.maxlength" }),
                    }
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.3_title"
                  label={ props.intl.formatMessage({ id: "field.title" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.4_email"
                  label={ props.intl.formatMessage({ id: "field.email" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isEmail(),
                      message: props.intl.formatMessage({ id: "validation.email" }),
                    },
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.5_company"
                  label={ props.intl.formatMessage({ id: "field.company" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <SelectField
                  name="step1.6_country"
                  label={ props.intl.formatMessage({ id: "field.country" }) }
                  required={props.intl.formatMessage({ id: "select.required" })}
                  options={props.currLang === "ar" ? COUNTRIES_AR : COUNTRIES_EN}
                />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="photography.step2"
          label={ props.intl.formatMessage({ id: "photography.step2" }) }
          >
            <Grid>
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_photography`}
                      group="nature_of_request"
                      id="tile_images"
                      label={ props.intl.formatMessage({ id: "field.photography" }) }
                      title={props.intl.formatMessage({ id: "field.nature_of_request" })}
                      required={prefLanguage && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_filming`}
                      group="nature_of_request"
                      id="tile_video"
                      label={ props.intl.formatMessage({ id: "field.filming" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
              </Row>
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.1_inside_buildings`}
                      group="film_or_photograph_location"
                      label={ props.intl.formatMessage({ id: "field.inside_buildings" }) }
                      title={props.intl.formatMessage({ id: "field.film_or_photograph_location" })}
                      required={prefLanguage && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.1_outside_buildings`}
                      group="film_or_photograph_location"
                      label={ props.intl.formatMessage({ id: "field.outside_buildings" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
              </Row>
              <Row>
                  <Column lg={{ span: 8, offset: 0 }}>
                    <TextAreaField
                      name={`step2.2_details_of_request`}
                      label={ props.intl.formatMessage({ id: "field.details_of_request" }) }
                      required={props.intl.formatMessage({ id: "field.required" })}
                      placeholder={props.intl.formatMessage({ id: "field.details_of_request_placeholder" })}
                    />
                  </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="photography.step3"
          label={ props.intl.formatMessage({ id: "photography.step3" }) }
          >
            <Grid className="no-padding-bottom">
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step3.0_traditional_media`}
                      group="publish_location"
                      id="tile_traditional_media"
                      label={ props.intl.formatMessage({ id: "field.traditional_media" }) }
                      title={props.intl.formatMessage({ id: "field.publish_location" })}
                      required={prefMedia && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={() => { 
                        setIsTraditionalMedia((x) => !x)
                        setPublishLocation()
                      }}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step3.0_socialmedia`}
                      group="publish_location"
                      id="tile_socialmedia"
                      label={ props.intl.formatMessage({ id: "field.socialmedia" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={() => { 
                        setIsSocialMedia((x) => !x)
                        setPublishLocation()
                      }}
                    />
                  </Column>
              </Row>
            </Grid>

            { isTraditionalMedia && (
              <Grid>
                <FormLabel><strong>{ props.intl.formatMessage({ id: "field.traditional_media" }) }</strong></FormLabel>
                {collection.map(({ id }, index) => (
                  <div key={id} data-test={`repeater-item[${index}]`}>
                    <Row>
                        <Column lg={{ span: 4, offset: 0 }}>
                          <InputField
                            name={`step3.collection[${index}].1_publication_name`}
                            label={ props.intl.formatMessage({ id: "field.publication_name" }) }
                            required={isTraditionalMedia && props.intl.formatMessage({ id: "field.required" })}
                          />
                        </Column>
                        <Column lg={{ span: 4, offset: 0 }}>
                          {collection.length <= 20 && (
                            <Button
                            iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                            size={'small'}
                            className={'more-button-sm'}
                            onClick={addItem}
                            kind={'primary'}
                            renderIcon={Add16}
                            >
                              { props.intl.formatMessage({ id: "button.addmore_short" }) }
                            </Button>
                          )}
                          {collection.length > 1 && (
                            <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                              size={'small'}
                              className={'delete-button-sm'}
                              onClick={() => removeItem(id)}
                              kind={'tertiary'}
                              renderIcon={Delete16}
                            >
                              { props.intl.formatMessage({ id: "button.remove" }) }
                            </Button>
                          )}
                        </Column>
                    </Row>
                  </div>
                ))}
                </Grid>
              )}

              { isSocialMedia && (
                <Grid>
                <FormLabel><strong>{ props.intl.formatMessage({ id: "field.socialmedia" }) } </strong></FormLabel>
                {smcollection.map(({ id }, index) => (
                  <div key={id} data-test={`repeater-item[${index}]`}>
                    <Row>
                        <Column lg={{ span: 4, offset: 0 }}>
                        <Row>
                          <Column lg={{ span: 5, offset: 0 }}>
                            <SelectField
                              name={`step3.smcollection[${index}].2_platform`}
                              label={ props.intl.formatMessage({ id: "field.select_platform" }) }
                              required={isSocialMedia && props.intl.formatMessage({ id: "select.required" })}
                              options={props.currLang === "ar" ? socialmediaAR : socialmedia}
                            />
                          </Column>
                          <Column lg={{ span: 7, offset: 0 }}>
                            <InputField
                              name={`step3.smcollection[${index}].2_url`}
                              label={ props.intl.formatMessage({ id: "field.url" }) }
                              required={isSocialMedia && props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          </Row>
                        </Column>
                        <Column lg={{ span: 4, offset: 0 }}>
                          {smcollection.length <= 20 && (
                            <Button
                            iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                            size={'small'}
                            className={'more-button'}
                            onClick={addSMItem}
                            kind={'primary'}
                            renderIcon={Add16}
                            >
                              { props.intl.formatMessage({ id: "button.addmore_short" }) }
                            </Button>
                          )}
                          {smcollection.length > 1 && (
                            <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                              size={'small'}
                              className={'delete-button'}
                              onClick={() => removeSmItem(id)}
                              kind={'tertiary'}
                              renderIcon={Delete16}
                            >
                              { props.intl.formatMessage({ id: "button.remove" }) }
                            </Button>
                          )}
                        </Column>
                    </Row>
                  </div>
                ))}
                </Grid>
              )}

          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="photography.step4"
          label={ props.intl.formatMessage({ id: "photography.step4" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "photography.step4" }) }</strong>
                    <div className="margin-top">
                    { props.intl.formatMessage({ id: "field.date_time_note" }) }
                    </div>
                  </FormLabel>
                </Column>
              </Row>
              {dateCollection.map(({ id }, index) => (
                  <div key={id} data-test={`repeater-item[${index}]`}>
                    <Row>
                    <Column lg={{ span: 4, offset: 0 }}>
                        <Row>
                          <Column lg={{ span: 5, offset: 0 }}>
                            <DatePickerField 
                              name={`step4.collection[${index}].0_from_date`}
                              label={ props.intl.formatMessage({ id: "field.from" }) }
                              required={prefTopic && props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          <Column lg={{ span: 5, offset: 0 }} className='no-gutter'>
                            <SelectField
                              name={`step4.collection[${index}].1_from_time`}
                              label={ props.intl.formatMessage({ id: "field.preferred_time" }) }
                              required={props.intl.formatMessage({ id: "select.required" })}
                              options={props.currLang === "ar" ? timeLongAR : timeLong}
                            />
                          </Column>
                        </Row>
                      </Column>
                      <Column lg={{ span: 4, offset: 0 }}>
                        <Row>
                          <Column lg={{ span: 5, offset: 0 }}>
                            <DatePickerField 
                              name={`step4.collection[${index}].2_to_date`}
                              label={ props.intl.formatMessage({ id: "field.to" }) }
                              required={prefTopic && props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          <Column lg={{ span: 5, offset: 0 }} className='no-gutter'>
                            <SelectField
                            name={`step4.collection[${index}].3_to_time`}
                            label={ props.intl.formatMessage({ id: "field.preferred_time" }) }
                            required={props.intl.formatMessage({ id: "select.required" })}
                            options={props.currLang === "ar" ? timeLongAR : timeLong}
                          />
                          </Column>
                        </Row>
                      </Column>
                      <Column lg={{ span: 4, offset: 0 }}>
                          {dateCollection.length <= 2 && (
                            <Button
                            iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                            size={'small'}
                            className={'more-button'}
                            onClick={addDateItem}
                            kind={'primary'}
                            renderIcon={Add16}
                            >
                              { props.intl.formatMessage({ id: "button.addmore_short" }) }
                            </Button>
                          )}
                          {dateCollection.length > 1 && (
                            <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                              size={'small'}
                              className={'delete-button'}
                              onClick={() => removeDateItem(id)}
                              kind={'tertiary'}
                              renderIcon={Delete16}
                            >
                              { props.intl.formatMessage({ id: "button.remove" }) }
                            </Button>
                          )}
                        </Column>
                    </Row>
                  </div>
                ))}
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="photography.step5"
          label={ props.intl.formatMessage({ id: "photography.step5" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "field.film_or_photograph_location_note" }) }</strong>
                  </FormLabel>
                </Column>
              </Row>
              {
                LocationsFilter()
              }
              {!isLocationValid && (
                <div className="bx--form-requirement margin-top-lg">
                  { props.intl.formatMessage({ id: "location_empty_validation" }) }
                </div>
              )}
              <Row id='locations'>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option1`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option1" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option2`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option2" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option3`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option3" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option4`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option4" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option5`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option5" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option6`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option6" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option7`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option7" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option8`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option8" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option9`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option9" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option10`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option10" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option11`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option11" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option12`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option12" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option13`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option13" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option14`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option14" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step5.1_location_option17`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option17" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="photography.step6"
          label={ props.intl.formatMessage({ id: "photography.step6" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "field.add_participants" }) }</strong>
                    <div className="margin-top">
                    { props.intl.formatMessage({ id: "field.add_participants_note" }) }
                    </div>
                  </FormLabel>
                  {collection.map(({ id }, index) => (
                    <div key={id} data-test={`repeater-item[${index}]`}>
                      <Row>
                          <Column lg={{ span: 4, offset: 0 }}>
                            <InputField
                              name={`step6.collection[${index}].0_name`}
                              label={ props.intl.formatMessage({ id: "field.name" }) }
                              required={props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          <Column lg={{ span: 4, offset: 0 }}>
                            <InputField
                              name={`step6.collection[${index}].0_title`}
                              label={ props.intl.formatMessage({ id: "field.title" }) }
                              required={props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          <Column lg={{ span: 4, offset: 0 }}>
                            {collection.length <= 20 && (
                              <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                              size={'small'}
                              className={'crew-more-button'}
                              onClick={addItem}
                              kind={'primary'}
                              renderIcon={Add16}
                              >
                                { props.intl.formatMessage({ id: "button.addmore_short" }) }
                              </Button>
                            )}
                            {collection.length > 1 && (
                              <Button
                                iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                                size={'small'}
                                className={'crew-delete-button'}
                                onClick={() => removeItem(id)}
                                kind={'tertiary'}
                                renderIcon={Delete16}
                              >
                                { props.intl.formatMessage({ id: "button.remove" }) }
                              </Button>
                            )}
                          </Column>
                      </Row>
                    </div>
                  ))}
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="photography.step7"
          label={ props.intl.formatMessage({ id: "form.steps.review" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <Review form='photography' data={ formData } />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
            /* @ts-ignore */
            <FormizStep
              name="photography.step8"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <h4 className="subheading">
                        { props.intl.formatMessage({ id: "thankyou" }) }
                      </h4>
                      <p>
                        {
                          props.intl.formatMessage({ id: "thankyou_content" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        <Footer form='photography' currentForm={qfForm} currLang={props.currLang} data={ formData } />
      </form>
    </Formiz>
  )
}

export default injectIntl(MultimediaWizard);
