import React, { useState, useEffect } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core'
import Review from '../Review'
import SelectField, { InputField, DatePickerField, TileField } from '../Fields'
import { ProgressIndicator, ProgressStep, Grid, Row, Column, FormLabel, Button, Search } from "carbon-components-react";
// @ts-ignore
import { COUNTRIES_EN, COUNTRIES_AR } from '../../data/Countries'
import { time, timeAR, BEAT_EN, BEAT_AR } from '../../data/Options'
import { isEmail, isNumber, isMaxLength } from '@formiz/validations'
import { injectIntl } from 'react-intl';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import { v4 as uuidv4 } from 'uuid';

const defaultCollection = [
  {
    id: uuidv4(),
    name: '',
  },
];

/* @ts-ignore */
const MediaToursWizard = (props: { currLang: any, intl: any }) => {
  const qfForm = useForm()
  const [collection, setCollection] = useState(defaultCollection);
  const [prefTours, setPrefTours] = useState(true);

  useEffect(() => {
    setCollection(defaultCollection);
  }, [qfForm.resetKey]);

  var radioboxes = []
  var allRadioboxes = document.querySelectorAll('input[type=radio]:checked')

  for (var n = 0; n < allRadioboxes.length; n++) {
    /* @ts-ignore */
    radioboxes.push({[allRadioboxes[n].name]: allRadioboxes[n].value})
  }

  var step1 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step1')).sort()
          .map(([_, v]) => v)

  var step2 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step2')).sort()
          .map(([_, v]) => v)

  var step3 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step3')).sort()
          .map(([_, v]) => v)     

  // @ts-ignore
  var selected_country = step1[0] && step1[0]['6_country']

  // @ts-ignore
  var pref_time = step3[0] && step3[0]['0_preferred_time']
  // @ts-ignore
  var alt_time = step3[0] && step3[0]['1_preferred_time']

  // @ts-ignore
  step1[0] && delete step1[0]['6_country']

  // @ts-ignore
  step2[0] && delete step2[0]['0_ec_stadium'] && delete step2[0]['0_innovation_and_education'] && delete step2[0]['0_art_and_culture'] && delete step2[0]['0_discover_qf']

  // @ts-ignore
  step3[0] && delete step3[0]['0_preferred_time'] && delete step3[0]['1_preferred_time'] && step3[0]['1_alternative_date'] === null && delete step3[0]['1_alternative_date']

  var checkedValue = []; 
  var inputElements = document.getElementsByName('tour_type');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedValue.push(inputElements[i].value);
      }
  }

  // @ts-ignore
  step1 = step1[0]
  // @ts-ignore
  step2 = step2[0]
  // @ts-ignore
  step3 = step3[0]

  // @ts-ignore
  step1 = {...step1, ['6_country']: selected_country };
  // @ts-ignore
  step2 = {...step2, ['0_tour_type']: checkedValue.join(', ')};
  // @ts-ignore
  step3 = {...step3, ['0_preferred_time']: pref_time , ['1_preferred_time']: alt_time};

  let formData = {
    step1,
    step2,
    step3
  }

  /* @ts-ignore */
  const switchStep = (element, step) => {
    element.preventDefault();
    qfForm.goToStep(step.name)
  }

  const SetToursType = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('tour_type');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefTours(false)
    } else {
      setPrefTours(true)
    }
  };

  return (
    <Formiz
      connect={qfForm}
      onValidSubmit={null}
    >
      <Header currentForm={qfForm} currLang={props.currLang} />
      <form
        noValidate
        onSubmit={qfForm.submitStep}
        className={'wizard mediatours'}
      >
        <Grid>
          {
            !qfForm.isLastStep && (
              <Row>
              <Column>
                <FormLabel>
                  <h2>
                    { props.intl.formatMessage({ id: "mediatours.title" }) }
                  </h2>
                </FormLabel>
              </Column>
            </Row>
            )
          }
          {!qfForm.isFirstStep && !qfForm.isLastStep && (
          <Row>
            <Column lg={{ span: 12, offset: 0 }}>
              {
                /* @ts-ignore */
                <ProgressIndicator spaceEqually={true}>
                  <div className="form__tabs mediatours">
                  {
                    /* @ts-ignore */
                    qfForm.steps.map((step, i) => (
                      i !== 0 && i !== (qfForm.steps.length - 1) && (
                        /* @ts-ignore */
                        <ProgressStep
                          onClick={(element) => switchStep(element, step)} 
                          label={step.label} 
                          key={step.name}
                          /* @ts-ignore */
                          current={step.name === qfForm.currentStep.name ? true: false} 
                          complete={step.isValid && step.isSubmitted ? true: false} 
                          disabled={ i === (qfForm.steps.length - 2) && !qfForm.isValid ? true : false }
                        />
                      )
                    ))
                    }
                  </div>
                </ProgressIndicator>
              }
            </Column>
          </Row>
          )}
          </Grid>
          {
            /* @ts-ignore */
            <FormizStep
              name="mediatours.step0"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <p>
                        {
                          props.intl.formatMessage({ id: "mediatours.intro_text" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
            name="mediatours.step1"
            label={ props.intl.formatMessage({ id: "form.steps.details" }) }
          >
            <Grid className='margin-md form'>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.1_name"
                  label={ props.intl.formatMessage({ id: "field.name" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.2_mobile"
                  label={ props.intl.formatMessage({ id: "field.mobile" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isNumber(),
                      message: props.intl.formatMessage({ id: "validation.mobile" }),
                    },
                    {
                      rule: isMaxLength(12),
                      message: props.intl.formatMessage({ id: "validation.mobile.maxlength" }),
                    }
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.3_title"
                  label={ props.intl.formatMessage({ id: "field.title" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.4_email"
                  label={ props.intl.formatMessage({ id: "field.email" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isEmail(),
                      message: props.intl.formatMessage({ id: "validation.email" }),
                    },
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.5_media_outlet"
                  label={ props.intl.formatMessage({ id: "field.media_outlet" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <SelectField
                  name="step1.6_country"
                  label={ props.intl.formatMessage({ id: "field.country" }) }
                  required={props.intl.formatMessage({ id: "select.required" })}
                  options={props.currLang === "ar" ? COUNTRIES_AR : COUNTRIES_EN}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                    <SelectField
                    name="step1.7_beat"
                    label={ props.intl.formatMessage({ id: "field.beat" }) }
                    required={props.intl.formatMessage({ id: "select.required" })}
                    options={props.currLang === "ar" ? BEAT_AR : BEAT_EN}
                    />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="mediatours.step2"
          label={ props.intl.formatMessage({ id: "mediatours.step2" }) }
          >
            <Grid>
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_ec_stadium`}
                      group="tour_type"
                      label={ props.intl.formatMessage({ id: "field.ec_stadium" }) }
                      title={props.intl.formatMessage({ id: "field.tour_type" })}
                      /* @ts-ignore */
                      onChange={e => SetToursType()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_innovation_and_education`}
                      group="tour_type"
                      label={ props.intl.formatMessage({ id: "field.innovation_and_education" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => SetToursType()}
                    />
                  </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_art_and_culture`}
                      group="tour_type"
                      label={ props.intl.formatMessage({ id: "field.art_and_culture" }) }
                      required={prefTours && props.intl.formatMessage({ id: "field.required" })}
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => SetToursType()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_discover_qf`}
                      group="tour_type"
                      label={ props.intl.formatMessage({ id: "field.discover_qf" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => SetToursType()}
                    />
                  </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="mediatours.step3"
          label={ props.intl.formatMessage({ id: "mediatours.step3" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "field.preferred_date_time" }) }</strong>
                    <div className="margin-top">
                    { props.intl.formatMessage({ id: "field.date_time_info" }) }
                    </div>
                  </FormLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 3, offset: 0 }}>
                  <DatePickerField 
                    name={`step3.0_preferred_date`}
                    label={ props.intl.formatMessage({ id: "field.preferred_date" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                </Column>
                <Column lg={{ span: 2, offset: 0 }}>
                  <SelectField
                    name={`step3.0_preferred_time`}
                    label={ props.intl.formatMessage({ id: "field.preferred_time" }) }
                    required={props.intl.formatMessage({ id: "select.required" })}
                    options={props.currLang === "ar" ? timeAR : time}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 3, offset: 0 }}>
                  <DatePickerField 
                    name={`step3.1_alternative_date`}
                    label={ props.intl.formatMessage({ id: "field.alternative_date" }) }
                  />
                </Column>
                <Column lg={{ span: 2, offset: 0 }}>
                  <SelectField
                    name={`step3.1_preferred_time`}
                    label={ props.intl.formatMessage({ id: "field.preferred_time" }) }
                    options={props.currLang === "ar" ? timeAR : time}
                  />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="mediatours.step4"
          label={ props.intl.formatMessage({ id: "form.steps.review" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <Review form='mediatours' data={ formData } />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
            /* @ts-ignore */
            <FormizStep
              name="mediatours.step5"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <h4 className="subheading">
                        { props.intl.formatMessage({ id: "thankyou" }) }
                      </h4>
                      <p>
                        {
                          props.intl.formatMessage({ id: "thankyou_content" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        <Footer form='mediatours' currentForm={qfForm} currLang={props.currLang} data={ formData } />
      </form>
    </Formiz>
  )
}

export default injectIntl(MediaToursWizard);
