import React, { useState } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core'
import Review from '../Review'
import SelectField, { InputField, TextAreaField, TileField, DatePickerField, RadioField, RadioTileField, MultiSelectField, FileUploaderField } from '../Fields'
import { ProgressIndicator, ProgressStep, Grid, Row, Column, FormLabel } from "carbon-components-react";
import { isEmail, isNumber, isMaxLength } from '@formiz/validations'
import { gender, genderAR, preferredJobFunction, preferredJobFunctionAR, jobFunction, jobFunctionAR, experience, experienceAR, industryField, industryFieldAR, highestQualification, highestQualificationAR, skillLevel, skillLevelAR } from '../../data/Options'
import { injectIntl } from 'react-intl';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

/* @ts-ignore */
const QatarizationWizard = (props: { currLang: any, intl: any }) => {
  const qfForm = useForm()
  const [empStatus, setEmpStatus] = useState(true);
  var collection: any = [];

  var radioboxes = []
  var allRadioboxes = document.querySelectorAll('input[type=radio]:checked')

  for (var n = 0; n < allRadioboxes.length; n++) {
    /* @ts-ignore */
    radioboxes.push({[allRadioboxes[n].name]: allRadioboxes[n].value})
  }

  var step1 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step1')).sort()
          .map(([_, v]) => v)

  var step2 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step2')).sort()
          .map(([_, v]) => v)

  var step3 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step3')).sort()
          .map(([_, v]) => v)        

  // @ts-ignore
  var selected_country = step1[0] && step1[0]['6_country']
  // @ts-ignore
  step1[0] && delete step1[0]['6_country']

  // @ts-ignore
  step2[0] && delete step2[0]['0_english'] && delete step2[0]['0_arabic']

  var checkedValue = []; 
  var inputElements = document.getElementsByClassName('bx--tile-input');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedValue.push(inputElements[i].value);
      }
  }

  // @ts-ignore
  var uploaded_file = step3[0] && step3[0]['9_file']

  // @ts-ignore
  step3[0] && delete step3[0]['9_file']

  // @ts-ignore
  var file_name = uploaded_file && uploaded_file.substr(0, uploaded_file.lastIndexOf("."))
  // @ts-ignore
  var file_ext = uploaded_file && uploaded_file.split('.').pop();

  var new_filename = file_name && file_name.substring(0, file_name.lastIndexOf("-")) + '.' + file_ext

  var language = props.currLang

  // @ts-ignore
  step1 = step1[0]
  // @ts-ignore
  step2 = step2[0]
  // @ts-ignore
  step3 = step3[0]

  // @ts-ignore
  step1 = {...step1, ['6_country']: selected_country };
  // @ts-ignore
  step2 = {...step2};
  // @ts-ignore
  step3 = {...step3, ['9_file']: new_filename};

  let formData = {
    step1,
    step2,
    step3,
    uploaded_file,
    language
  }

  /* @ts-ignore */
  const switchStep = (element, step) => {
    element.preventDefault();
    qfForm.goToStep(step.name)
  }
  
  // @ts-ignore
  const showAdditionalFields = (empStatus !== "Student" && empStatus !== "طالب") ? true : false

  return (
    <Formiz
      connect={qfForm}
      onValidSubmit={null}
    >
      <Header currentForm={qfForm} currLang={props.currLang} />
      <form
        noValidate
        onSubmit={qfForm.submitStep}
        className={'wizard qatarization'}
      >
        <Grid>
          {
            !qfForm.isLastStep && (
              <Row>
              <Column>
                <FormLabel>
                  <h2>
                    { props.intl.formatMessage({ id: "qatarization.title" }) }
                  </h2>
                </FormLabel>
              </Column>
            </Row>
            )
          }
          {!qfForm.isFirstStep && !qfForm.isLastStep && (
          <Row>
            <Column lg={{ span: 12, offset: 0 }}>
              {
                /* @ts-ignore */
                <ProgressIndicator spaceEqually={true}>
                  <div className="form__tabs">
                  {
                    /* @ts-ignore */
                    qfForm.steps.map((step, i) => (
                      i !== 0 && i !== (qfForm.steps.length - 1) && (
                        /* @ts-ignore */
                        <ProgressStep
                          onClick={(element) => switchStep(element, step)} 
                          label={step.label} 
                          key={step.name}
                          /* @ts-ignore */
                          current={step.name === qfForm.currentStep.name ? true: false} 
                          complete={step.isValid && step.isSubmitted ? true: false} 
                          disabled={ i === (qfForm.steps.length - 2) && !qfForm.isValid ? true : false }
                        />
                      )
                    ))
                    }
                  </div>
                </ProgressIndicator>
              }
            </Column>
          </Row>
          )}
          </Grid>
          {
            /* @ts-ignore */
            <FormizStep
              name="qatarization.step0"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <p>
                        {
                          props.intl.formatMessage({ id: "qatarization.intro_text" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
            name="qatarization.step1"
            label={ props.intl.formatMessage({ id: "form.steps.details" }) }
          >
            <Grid className='margin-md form'>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.1_name"
                  label={ props.intl.formatMessage({ id: "field.name" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.2_mobile"
                  label={ props.intl.formatMessage({ id: "field.mobile" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isNumber(),
                      message: props.intl.formatMessage({ id: "validation.mobile" }),
                    },
                    {
                      rule: isMaxLength(12),
                      message: props.intl.formatMessage({ id: "validation.mobile.maxlength" }),
                    }
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.3_email"
                  label={ props.intl.formatMessage({ id: "field.email" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isEmail(),
                      message: props.intl.formatMessage({ id: "validation.email" }),
                    },
                  ]}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                  <DatePickerField 
                    name={`step1.4_dob`}
                    label={ props.intl.formatMessage({ id: "field.dob" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                    maxDate={new Date().toLocaleDateString()} 
                    currLang={props.currLang}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                  <SelectField
                    name={`step1.5_gender`}
                    label={ props.intl.formatMessage({ id: "field.gender" }) }
                    required={props.intl.formatMessage({ id: "select.required" })}
                    options={props.currLang === "ar" ? genderAR : gender}
                  />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                  <InputField
                    name="step1.6_qid"
                    label={ props.intl.formatMessage({ id: "field.qid" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="qatarization.step2"
          label={ props.intl.formatMessage({ id: "qatarization.step2" }) }
          >
            <Grid>
              <Row>
                  <Column lg={{ span: 12, offset: 0 }}>
                    <RadioTileField
                      name="step2.0_employee_status"
                      checked={ props.intl }
                      // @ts-ignore
                      onChange={e => setEmpStatus(e)}
                      options={
                        {
                          "student": props.intl.formatMessage({ id: "field.student" }),
                          "employee": props.intl.formatMessage({ id: "field.employee" }),
                          "unemployed": props.intl.formatMessage({ id: "field.unemployed" }),
                        }
                      }
                      label={ props.intl.formatMessage({ id: "field.employee_status" }) }
                    />
                  </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                  <br />
                  <MultiSelectField
                    name={`step2.1_preferred_job_function`}
                    label={ props.intl.formatMessage({ id: "field.preferred_job_function" }) }
                    options={props.currLang === "ar" ? preferredJobFunctionAR : preferredJobFunction}
                    id={'preferred_job_function'}
                    searchPlaceholder={props.intl.formatMessage({ id: "field.search" })}
                    required={props.intl.formatMessage({ id: "select.required" })}
                  />
                </Column>
              </Row>
              <Row>
                  <Column lg={{ span: 8, offset: 0 }}>
                  <RadioField
                    name="step2.2_desired_employment_type"
                    checked={ props.intl }
                    options={
                      {
                        "trainee": props.intl.formatMessage({ id: "field.trainee" }),
                        "internship": props.intl.formatMessage({ id: "field.internship" }),
                        "fulltime": props.intl.formatMessage({ id: "field.fulltime" }),
                      }
                    }
                    label={ props.intl.formatMessage({ id: "field.desired_employment_type" }) }
                  />
                  </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="qatarization.step3"
          label={ props.intl.formatMessage({ id: "qatarization.step3" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                  <SelectField
                      name={`step3.0_highest_qualification`}
                      label={ props.intl.formatMessage({ id: "field.highest_qualification" }) }
                      required={props.intl.formatMessage({ id: "select.required" })}
                      options={props.currLang === "ar" ? highestQualificationAR : highestQualification}
                  />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                  <InputField
                    name="step3.1_areas_of_study"
                    label={ props.intl.formatMessage({ id: "field.areas_of_study" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                  <InputField
                    name="step3.2_institute_university_name"
                    label={ props.intl.formatMessage({ id: "field.institute_university_name" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                  <InputField
                    name="step3.3_gpa"
                    label={ props.intl.formatMessage({ id: "field.gpa" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                  <SelectField
                      name={`step3.4_english_fluency`}
                      label={ props.intl.formatMessage({ id: "field.english_fluency" }) }
                      required={props.intl.formatMessage({ id: "select.required" })}
                      options={props.currLang === "ar" ? skillLevelAR : skillLevel}
                  />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                  <SelectField
                      name={`step3.5_computer_literacy`}
                      label={ props.intl.formatMessage({ id: "field.computer_literacy" }) }
                      required={props.intl.formatMessage({ id: "select.required" })}
                      options={props.currLang === "ar" ? skillLevelAR : skillLevel}
                  />
                </Column>
              </Row>
              {
                showAdditionalFields && (
                  <span>
                    <Row>
                      <Column lg={{ span: 4, offset: 0 }}>
                        <SelectField
                            name={`step3.6_total_years_of_experience`}
                            label={ props.intl.formatMessage({ id: "field.total_years_of_experience" }) }
                            required={props.intl.formatMessage({ id: "select.required" })}
                            options={props.currLang === "ar" ? experienceAR : experience}
                        />
                      </Column>
                      <Column lg={{ span: 4, offset: 0 }}>
                        <InputField
                          name="step3.7_current_position_title"
                          label={ props.intl.formatMessage({ id: "field.current_position_title" }) }
                          required={props.intl.formatMessage({ id: "field.required" })}
                        />
                      </Column>
                      <Column lg={{ span: 4, offset: 0 }}>
                        <InputField
                          name="step3.8_company_name"
                          label={ props.intl.formatMessage({ id: "field.company_name" }) }
                          required={props.intl.formatMessage({ id: "field.required" })}
                        />
                      </Column>
                    </Row>
                    <Row>
                      <Column lg={{ span: 4, offset: 0 }}>
                        <SelectField
                          name={`step3.9_industry_field`}
                          label={ props.intl.formatMessage({ id: "field.industry_field" }) }
                          required={props.intl.formatMessage({ id: "select.required" })}
                          options={props.currLang === "ar" ? industryFieldAR : industryField}
                          id={'industry_field'}
                        />
                      </Column>
                      <Column lg={{ span: 4, offset: 0 }}>
                        <SelectField
                          name={`step3.9_job_function`}
                          label={ props.intl.formatMessage({ id: "field.job_function" }) }
                          required={props.intl.formatMessage({ id: "select.required" })}
                          options={props.currLang === "ar" ? jobFunctionAR : jobFunction}
                          id={'job_function'}
                        />
                      </Column>
                    </Row>
                  </span>
                )
              }
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                {
                  /* @ts-ignore */
                  <FileUploaderField 
                    name="step3.9_file" 
                    id="file" 
                    label={ props.intl.formatMessage({ id: "field.upload_resume" }) }
                    acceptFileType={['.jpg', '.png', '.doc', '.docx', '.rtf', '.pdf', '.zip']}
                    form='qatarization'
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                }
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="qatarization.step4"
          label={ props.intl.formatMessage({ id: "form.steps.review" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <Review form='qatarization' data={ formData } />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
            /* @ts-ignore */
            <FormizStep
              name="qatarization.step5"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <h4 className="subheading">
                        { props.intl.formatMessage({ id: "thankyou" }) }
                      </h4>
                      <p>
                        {
                          props.intl.formatMessage({ id: "qatarization.thankyou_content" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        <Footer form='qatarization' currentForm={qfForm} currLang={props.currLang} data={ formData } />
      </form>
    </Formiz>
  )
}

export default injectIntl(QatarizationWizard);