import React, { useState, useEffect } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core'
import Review from '../Review'
import SelectField, { InputField, TextAreaField, TileField } from '../Fields'
import { ProgressIndicator, ProgressStep, Grid, Row, Column, FormLabel, Button } from "carbon-components-react";
import { Add16, Delete16 } from '@carbon/icons-react';
// @ts-ignore
import { COUNTRIES_EN, COUNTRIES_AR } from '../../data/Countries'
import { socialmedia, socialmediaAR } from '../../data/Options'
import { isEmail, isNumber, isMaxLength } from '@formiz/validations'
import { injectIntl } from 'react-intl';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';

const defaultCollection = [
  {
    id: uuidv4(),
    name: '',
  },
];

const defaultSMCollection = [
  {
    id: uuidv1(),
    name: '',
  },
];

/* @ts-ignore */
const MultimediaWizard = (props: { currLang: any, intl: any }) => {
  const qfForm = useForm()
  const [collection, setCollection] = useState(defaultCollection);
  const [smcollection, setSmcollection] = useState(defaultSMCollection);
  const [prefLanguage, setPrefLanguage] = useState(true);
  const [prefMedia, setPrefMedia] = useState(true);
  const [isTraditionalMedia, setIsTraditionalMedia] = useState(false);
  const [isSocialMedia, setIsSocialMedia] = useState(false);

  var traditional_media_collection: any = [];
  var social_media_collection: any = [];

  useEffect(() => {
    setIsTraditionalMedia(false);
    setIsSocialMedia(false);
  }, [qfForm.resetKey]);

  useEffect(() => {
    setCollection(defaultCollection);
    setSmcollection(defaultCollection);
  }, [qfForm.resetKey]);

  const addItem = () => {
    setCollection((c) => [
      ...c,
      {
        id: uuidv4(),
        name: ''
      },
    ]);
  };

  /* @ts-ignore */
  const removeItem = (id) => {
    setCollection((c) => c.filter((x) => x.id !== id));
  };

  const addSMItem = () => {
    setSmcollection((c) => [
      ...c,
      {
        id: uuidv4(),
        name: ''
      },
    ]);
  };

  /* @ts-ignore */
  const removeSmItem = (id) => {
    setSmcollection((c) => c.filter((x) => x.id !== id));
  };

  var radioboxes = []
  var allRadioboxes = document.querySelectorAll('input[type=radio]:checked')

  for (var n = 0; n < allRadioboxes.length; n++) {
    /* @ts-ignore */
    radioboxes.push({[allRadioboxes[n].name]: allRadioboxes[n].value})
  }

  var step1 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step1')).sort()
          .map(([_, v]) => v)

  var step2 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step2')).sort()
          .map(([_, v]) => v)

  var step3 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step3')).sort()
          .map(([_, v]) => v)        

  // @ts-ignore
  var selected_country = step1[0] && step1[0]['6_country']
  // @ts-ignore
  step1[0] && delete step1[0]['6_country']

  // @ts-ignore
  step2[0] && delete step2[0]['0_images'] && delete step2[0]['0_video']

  // @ts-ignore
  step3[0] && delete step3[0]['0_traditional_media'] && delete step3[0]['0_socialmedia']

  var checkedValue = []; 
  var inputElements = document.getElementsByName('type_of_media');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedValue.push(inputElements[i].value);
      }
  }

  var publishLocationValue = []; 
  var inputElements = document.getElementsByName('publish_location');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          publishLocationValue.push(inputElements[i].value);
      }
  }

  // @ts-ignore
  step1 = step1[0]
  // @ts-ignore
  step2 = step2[0]
  // @ts-ignore
  step3 = step3[0]

  // @ts-ignore
  step1 = {...step1, ['6_country']: selected_country };
  // @ts-ignore
  step2 = {...step2, ['0_type_of_media']: checkedValue.join(', ')};
  // @ts-ignore
  step3 = {...step3, ['0_publish_location']: publishLocationValue.join(', ')};

  
  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step3' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.collection && field.collection
          // @ts-ignore
          items && items.forEach((item, i) => {
            traditional_media_collection.push(`Name of the publication/broadcast outlet: #${i + 1}`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                traditional_media_collection.push(` ${ item[k] }`)
                n === (Object.keys(item).length - 1) && traditional_media_collection.push(`\n`)
            })
          })
      });
  });

  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step3' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.smcollection && field.smcollection
          // @ts-ignore
          items && items.forEach((item, i) => {
            social_media_collection.push(`Platform: #${i + 1}`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                social_media_collection.push(`${ item[k] }`)
                n === (Object.keys(item).length - 1) && social_media_collection.push(`\n`)
            })
          })
      });
  });

  var traditional_media_collections = traditional_media_collection.join(",").split(",").join("\n")
  var social_media_collections = social_media_collection.join(",").split(",").join("\n")

  let formData = {
    step1,
    step2,
    step3,
    traditional_media_collections,
    social_media_collections
  }

  /* @ts-ignore */
  const switchStep = (element, step) => {
    element.preventDefault();
    qfForm.goToStep(step.name)
  }

  const preferredLanguage = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('type_of_media');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefLanguage(false)
    } else {
      setPrefLanguage(true)
    }
  };

  const setPublishLocation = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('publish_location');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    console.log(checkedValue)
    if(checkedValue) {
      setPrefMedia(false)
    } else {
      setPrefMedia(true)
    }
  };

  return (
    <Formiz
      connect={qfForm}
      onValidSubmit={null}
    >
      <Header currentForm={qfForm} currLang={props.currLang} />
      <form
        noValidate
        onSubmit={qfForm.submitStep}
        className={'wizard'}
      >
        <Grid>
          {
            !qfForm.isLastStep && (
              <Row>
              <Column>
                <FormLabel>
                  <h2>
                    { props.intl.formatMessage({ id: "multimedia.title" }) }
                  </h2>
                </FormLabel>
              </Column>
            </Row>
            )
          }
          {!qfForm.isFirstStep && !qfForm.isLastStep && (
          <Row>
            <Column lg={{ span: 12, offset: 0 }}>
              {
                /* @ts-ignore */
                <ProgressIndicator spaceEqually={true}>
                  <div className="form__tabs">
                  {
                    /* @ts-ignore */
                    qfForm.steps.map((step, i) => (
                      i !== 0 && i !== (qfForm.steps.length - 1) && (
                        /* @ts-ignore */
                        <ProgressStep
                          onClick={(element) => switchStep(element, step)} 
                          label={step.label} 
                          key={step.name}
                          /* @ts-ignore */
                          current={step.name === qfForm.currentStep.name ? true: false} 
                          complete={step.isValid && step.isSubmitted ? true: false} 
                          disabled={ i === (qfForm.steps.length - 2) && !qfForm.isValid ? true : false }
                        />
                      )
                    ))
                    }
                  </div>
                </ProgressIndicator>
              }
            </Column>
          </Row>
          )}
          </Grid>
          {
            /* @ts-ignore */
            <FormizStep
              name="multimedia.step0"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <p>
                        {
                          props.intl.formatMessage({ id: "multimedia.intro_text" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
            name="multimedia.step1"
            label={ props.intl.formatMessage({ id: "form.steps.details" }) }
          >
            <Grid className='margin-md form'>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.1_name"
                  label={ props.intl.formatMessage({ id: "field.name" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.2_mobile"
                  label={ props.intl.formatMessage({ id: "field.mobile" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isNumber(),
                      message: props.intl.formatMessage({ id: "validation.mobile" }),
                    },
                    {
                      rule: isMaxLength(12),
                      message: props.intl.formatMessage({ id: "validation.mobile.maxlength" }),
                    }
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.3_title"
                  label={ props.intl.formatMessage({ id: "field.title" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.4_email"
                  label={ props.intl.formatMessage({ id: "field.email" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isEmail(),
                      message: props.intl.formatMessage({ id: "validation.email" }),
                    },
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.5_company"
                  label={ props.intl.formatMessage({ id: "field.company" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <SelectField
                  name="step1.6_country"
                  label={ props.intl.formatMessage({ id: "field.country" }) }
                  required={props.intl.formatMessage({ id: "select.required" })}
                  options={props.currLang === "ar" ? COUNTRIES_AR : COUNTRIES_EN}
                />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="multimedia.step2"
          label={ props.intl.formatMessage({ id: "multimedia.step2" }) }
          >
            <Grid>
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_images`}
                      group="type_of_media"
                      id="tile_images"
                      label={ props.intl.formatMessage({ id: "field.images" }) }
                      title={props.intl.formatMessage({ id: "field.type_of_media" })}
                      required={prefLanguage && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_video`}
                      group="type_of_media"
                      id="tile_video"
                      label={ props.intl.formatMessage({ id: "field.video" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
              </Row>
              <Row>
                  <Column lg={{ span: 8, offset: 0 }}>
                    <TextAreaField
                      name={`step2.1_specification`}
                      label={ props.intl.formatMessage({ id: "field.specification" }) }
                      required={props.intl.formatMessage({ id: "field.required" })}
                    />
                  </Column>
              </Row>
              <Row>
                  <Column lg={{ span: 8, offset: 0 }}>
                    <TextAreaField
                      name={`step2.1_details_of_request`}
                      label={ props.intl.formatMessage({ id: "field.details_of_request" }) }
                      required={props.intl.formatMessage({ id: "field.required" })}
                    />
                  </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="multimedia.step3"
          label={ props.intl.formatMessage({ id: "multimedia.step3" }) }
          >
            <Grid className="no-padding-bottom">
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step3.0_traditional_media`}
                      group="publish_location"
                      id="tile_traditional_media"
                      label={ props.intl.formatMessage({ id: "field.traditional_media" }) }
                      title={props.intl.formatMessage({ id: "field.publish_location" })}
                      required={prefMedia && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={() => { 
                        setIsTraditionalMedia((x) => !x)
                        setPublishLocation()
                      }}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step3.0_socialmedia`}
                      group="publish_location"
                      id="tile_socialmedia"
                      label={ props.intl.formatMessage({ id: "field.socialmedia" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={() => { 
                        setIsSocialMedia((x) => !x)
                        setPublishLocation()
                      }}
                    />
                  </Column>
              </Row>
            </Grid>

            { isTraditionalMedia && (
              <Grid>
                <FormLabel><strong>{ props.intl.formatMessage({ id: "field.traditional_media" }) }</strong></FormLabel>
                {collection.map(({ id }, index) => (
                  <div key={id} data-test={`repeater-item[${index}]`}>
                    <Row>
                        <Column lg={{ span: 4, offset: 0 }}>
                          <InputField
                            name={`step3.collection[${index}].1_publication_name`}
                            label={ props.intl.formatMessage({ id: "field.publication_name" }) }
                            required={isTraditionalMedia && props.intl.formatMessage({ id: "field.required" })}
                          />
                        </Column>
                        <Column lg={{ span: 4, offset: 0 }}>
                          {collection.length <= 20 && (
                            <Button
                            iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                            size={'small'}
                            className={'more-button'}
                            onClick={addItem}
                            kind={'primary'}
                            renderIcon={Add16}
                            >
                              { props.intl.formatMessage({ id: "button.addmore_short" }) }
                            </Button>
                          )}
                          {collection.length > 1 && (
                            <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                              size={'small'}
                              className={'delete-button'}
                              onClick={() => removeItem(id)}
                              kind={'tertiary'}
                              renderIcon={Delete16}
                            >
                              { props.intl.formatMessage({ id: "button.remove" }) }
                            </Button>
                          )}
                        </Column>
                    </Row>
                  </div>
                ))}
                </Grid>
              )}

              { isSocialMedia && (
                <Grid>
                <FormLabel><strong>{ props.intl.formatMessage({ id: "field.socialmedia" }) } </strong></FormLabel>
                {smcollection.map(({ id }, index) => (
                  <div key={id} data-test={`repeater-item[${index}]`}>
                    <Row>
                        <Column lg={{ span: 4, offset: 0 }}>
                        <Row>
                          <Column lg={{ span: 5, offset: 0 }}>
                            <SelectField
                              name={`step3.smcollection[${index}].2_platform`}
                              label={ props.intl.formatMessage({ id: "field.select_platform" }) }
                              required={isSocialMedia && props.intl.formatMessage({ id: "select.required" })}
                              options={props.currLang === "ar" ? socialmediaAR : socialmedia}
                            />
                          </Column>
                          <Column lg={{ span: 7, offset: 0 }}>
                            <InputField
                              name={`step3.smcollection[${index}].2_url`}
                              label={ props.intl.formatMessage({ id: "field.url" }) }
                              required={isSocialMedia && props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          </Row>
                        </Column>
                        <Column lg={{ span: 4, offset: 0 }}>
                          {smcollection.length <= 20 && (
                            <Button
                            iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                            size={'small'}
                            className={'more-button-sm'}
                            onClick={addSMItem}
                            kind={'primary'}
                            renderIcon={Add16}
                            >
                              { props.intl.formatMessage({ id: "button.addmore_short" }) }
                            </Button>
                          )}
                          {smcollection.length > 1 && (
                            <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                              size={'small'}
                              className={'delete-button-sm'}
                              onClick={() => removeSmItem(id)}
                              kind={'tertiary'}
                              renderIcon={Delete16}
                            >
                              { props.intl.formatMessage({ id: "button.remove" }) }
                            </Button>
                          )}
                        </Column>
                    </Row>
                  </div>
                ))}
                </Grid>
              )}

          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="multimedia.step4"
          label={ props.intl.formatMessage({ id: "form.steps.review" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <Review form='multimedia' data={ formData } />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
            /* @ts-ignore */
            <FormizStep
              name="multimedia.step5"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <h4 className="subheading">
                        { props.intl.formatMessage({ id: "thankyou" }) }
                      </h4>
                      <p>
                        {
                          props.intl.formatMessage({ id: "thankyou_content" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        <Footer form='multimedia' currentForm={qfForm} currLang={props.currLang} data={ formData } />
      </form>
    </Formiz>
  )
}

export default injectIntl(MultimediaWizard);