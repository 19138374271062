export const relationship = {
    '1': "Employee",
    '2': "Student",
    '3': "Graduate trainee",
    '4': "Subcontractor",
    '5': "Consultant",
    '6': "Associated researcher",
    '7': "Other"
}
export const relationshipAR = {
    '1': "الموظف",
    '2': "الموظف",
    '3': "الطالب",
    '4': "المتدرب الخريج",
    '5': "المقاول الفرعي",
    '6': "الباحث المشارك",
    '7': "غير ذلك"
}

export const source = {
    '1': "News",
    '2': "Website",
    '3': "Maktabi",
    '4': "Advertisement",
    '5': "Other"
}
export const sourceAR = {
    '1': "الأخبار",
    '2': "الموقع",
    '3': "مكتبي",
    '4': "إعلان",
    '5': "غير ذلك"
}

export const socialmedia = {
    '1': "Facebook",
    '2': "Twitter",
    '3': "Instagram",
    '4': "Other"
}
export const socialmediaAR = {
    '1': "الفيسبوك",
    '2': "تويتر",
    '3': "انستغرام",
    '4': "غير ذلك"
}

export const time = {
    "1": "09:00 am",
    "2": "09:30 am",
    "3": "10:00 am",
    "4": "10:30 am",
    "5": "11:00 am",
    "6": "11:30 am",
    "7": "12:00 pm",
    "8": "12:30 pm",
    "9": "01:00 pm",
    "10": "01:30 pm",
    "11": "02:00 pm",
    "12": "02:30 pm"
}
export const timeAR = {
    "1": "09:00 صباحا",
    "2": "09:30 صباحا",
    "3": "10:00 صباحا",
    "4": "10:30 صباحا",
    "5": "11:00 صباحا",
    "6": "11:30 صباحا",
    "7": "12:00 مساء",
    "8": "12:30 مساء",
    "9": "01:00 مساء",
    "10": "01:30 مساء",
    "11": "02:00 مساء",
    "12": "02:30 مساء"
}

export const timeLong = {
    "1": "09:00 am",
    "2": "09:30 am",
    "3": "10:00 am",
    "4": "10:30 am",
    "5": "11:00 am",
    "6": "11:30 am",
    "7": "12:00 pm",
    "8": "12:30 pm",
    "9": "01:00 pm",
    "10": "01:30 pm",
    "11": "02:00 pm",
    "12": "02:30 pm",
    "13": "03:00 pm",
    "14": "03:30 pm",
    "15": "04:00 pm",
    "16": "04:30 pm",
    "17": "05:00 pm",
    "18": "05:30 pm",
    "19": "06:00 pm",
    "20": "06:30 pm",
    "21": "07:00 pm",
    "22": "07:30 pm",
    "23": "08:00 pm",
    "24": "08:30 pm",
    "25": "09:00 pm",
    "26": "09:30 pm",
    "27": "10:00 pm",
    "28": "10:30 pm",
    "29": "11:00 pm",
    "30": "11:30 pm"
}
export const timeLongAR = {
    "1": "09:00 صباحا",
    "2": "09:30 صباحا",
    "3": "10:00 صباحا",
    "4": "10:30 صباحا",
    "5": "11:00 صباحا",
    "6": "11:30 صباحا",
    "7": "12:00 مساء",
    "8": "12:30 مساء",
    "9": "01:00 مساء",
    "10": "01:30 مساء",
    "11": "02:00 مساء",
    "12": "02:30 مساء",
    "13": "03:00 مساء",
    "14": "03:30 مساء",
    "15": "04:00 مساء",
    "16": "04:30 مساء",
    "17": "05:00 مساء",
    "18": "05:30 مساء",
    "19": "06:00 مساء",
    "20": "06:30 مساء",
    "21": "07:00 مساء",
    "22": "07:30 مساء",
    "23": "08:00 مساء",
    "24": "08:30 مساء",
    "25": "09:00 مساء",
    "26": "09:30 مساء",
    "27": "10:00 مساء",
    "28": "10:30 مساء",
    "29": "11:00 مساء",
    "30": "11:30 مساء"
}

export const gender = {
    "1": "Male",
    "2": "Female"
}
export const genderAR = {
    "1": "أنثى",
    "2": "ذكر"
}

export const highestQualification = {
    "1": "PhD",
    "2": "Master",
    "3": "Bachelor",
    "4": "Diploma",
    "5": "High School",
}
export const highestQualificationAR = {
    "1": "دكتوراه",
    "2": "ماجستير",
    "3": "بكالوريوس",
    "4": "دبلوما",
    "5": "ثانوية عامة",
}

export const skillLevel = {
    "1": "Excellent",
    "2": "Good",
    "3": "Fair",
    "4": "None",
}
export const skillLevelAR = {
    "1": "جيد",
    "2": "متوسط",
    "3": "لا أجيدها",
    "4": "ممتاز",
}

export const experience = {
    "1": "0 to 2",
    "2": "3 to 5",
    "3": "6 to 8",
    "4": "9 and over",
}
export const experienceAR = {
    "1": "٠ إلى ٢",
    "2": "٣ إلى ٥",
    "3": "٦ إلى ٨",
    "4": "٩ وأكثر",
}

export const preferredJobFunction = [
    {
        value: "1",
        label: "Administration / Business Support"
    },
    {
        value: "2",
        label: "Community / Social Services"
    },
    {
        value: "3",
        label: "Customer Service"
    },
    {
        value: "4",
        label: "Education / Teaching / Libraries"
    },
    {
        value: "5",
        label: "Events / Media"
    },
    {
        value: "6",
        label: "Financial Services"
    },
    {
        value: "7",
        label: "Healthcare"
    },
    {
        value: "8",
        label: "Human Resources"
    },
    {
        value: "9",
        label: "Information Technology"
    },
    {
        value: "10",
        label: "Journalism"
    },
    {
        value: "11",
        label: "Legal"
    },
    {
        value: "12",
        label: "Management / Strategy"
    },
    {
        value: "13",
        label: "Marketing / Communications"
    },
    {
        value: "14",
        label: "Mechanical / Electrical Engineering"
    },
    {
        value: "15",
        label: "Procurement / Warehousing"
    },
    {
        value: "16",
        label: "Protocol / External Relations"
    },
    {
        value: "17",
        label: "Public Relations / Outreach"
    },
    {
        value: "18",
        label: "Safety / Environment"
    },
    {
        value: "19",
        label: "Scientific Research / Laboratory"
    },
    {
        value: "20",
        label: "Security"
    },
    {
        value: "21",
        label: "Sports and Recreation"
    },
    {
        value: "22",
        label: "Support Services"
    },
    {
        value: "23",
        label: "Technical / Maintenance"
    }
]
export const preferredJobFunctionAR = [
    {
        value: "1",
        label: "الدعم الإداري/التجاري"
    },
    {
        value: "2",
        label: "الخدمات الاجتماعية/المجتمعية"
    },
    {
        value: "3",
        label: "خدمة العملاء"
    },
    {
        value: "4",
        label: "التعليم/ التدريس/ المكتبات"
    },
    {
        value: "5",
        label: "الفعاليات/ الإعلام"
    },
    {
        value: "6",
        label: "الخدمات المالية"
    },
    {
        value: "7",
        label: "الرعاية الصحية"
    },
    {
        value: "8",
        label: "الموارد البشرية"
    },
    {
        value: "9",
        label: "تكنولوجيا المعلومات"
    },
    {
        value: "10",
        label: "الصحافة"
    },
    {
        value: "11",
        label: "الشؤون القانونية"
    },
    {
        value: "12",
        label: "الاستراتيجية/ الإدارة"
    },
    {
        value: "13",
        label: "التسويق/ الاتصالات"
    },
    {
        value: "14",
        label: "الهندسة الميكانيكية/الكهربائية"
    },
    {
        value: "15",
        label: "المشتريات/ التخزين"
    },
    {
        value: "16",
        label: "البروتوكول/ العلاقات الخارجية"
    },
    {
        value: "17",
        label: "العلاقات العامة/ التواصل"
    },
    {
        value: "18",
        label: "الأمان/ البيئة"
    },
    {
        value: "19",
        label: "البحث العلمي/ المختبر"
    },
    {
        value: "20",
        label: "الأمن"
    },
    {
        value: "21",
        label: "الرياضة والترفيه"
    },
    {
        value: "22",
        label: "خدمات الدعم"
    },
    {
        value: "23",
        label: "الصيانة/ الدعم التقني"
    }
]

export const jobFunction = {
    "1": "Administration / Business Support",
    "2": "Community / Social Services",
    "3": "Customer Service",
    "4": "Education / Teaching / Libraries",
    "5": "Events / Media",
    "6": "Financial Services",
    "7": "Healthcare",
    "8": "Human Resources",
    "9": "Information Technology",
    "10": "Journalism",
    "11": "Legal",
    "12": "Management / Strategy",
    "13": "Marketing / Communications",
    "14": "Mechanical / Electrical Engineering",
    "15": "Procurement / Warehousing",
    "16": "Protocol / External Relations",
    "17": "Public Relations / Outreach",
    "18": "Safety / Environment",
    "19": "Scientific Research / Laboratory",
    "20": "Security",
    "21": "Sports and Recreation",
    "22": "Support Services",
    "23": "Technical / Maintenance"
}
export const jobFunctionAR = {
    "1": "الدعم الإداري/التجاري",
    "2": "الخدمات الاجتماعية/المجتمعية",
    "3": "خدمة العملاء",
    "4": "التعليم/ التدريس/ المكتبات",
    "5": "الفعاليات/ الإعلام",
    "6": "الخدمات المالية",
    "7": "الرعاية الصحية",
    "8": "الموارد البشرية",
    "9": "تكنولوجيا المعلومات",
    "10": "الصحافة",
    "11": "الشؤون القانونية",
    "12": "الاستراتيجية/ الإدارة",
    "13": "التسويق/ الاتصالات",
    "14": "الهندسة الميكانيكية/الكهربائية",
    "15": "المشتريات/ التخزين",
    "16": "البروتوكول/ العلاقات الخارجية",
    "17": "العلاقات العامة/ التواصل",
    "18": "الأمان/ البيئة",
    "19": "البحث العلمي/ المختبر",
    "20": "الأمن",
    "21": "الرياضة والترفيه",
    "22": "خدمات الدعم",
    "23": "الصيانة/ الدعم التقني"
}

export const industryField = {
    "1": "Accounting / Auditing",
    "2": "Administration",
    "3": "Advertising",
    "4": "Agriculture",
    "5": "Airlines / Aviation",
    "6": "Architecture",
    "7": "Arts / Entertainment / Media",
    "8": "Automotive",
    "9": "Banking",
    "10": "Biotechnology",
     "11": "Business Support",
     "12": "Catering / Food Services",
     "13": "Community / Social Service / Nonprofit",
     "14": "Information Technology",
     "15": "Construction",
     "16": "Consulting Services",
     "17": "Contracts / Purchasing",
     "18": "Customer Service",
     "19": "Distributions and Logistics",
     "20": "Education",
     "21": "Energy",
     "22": "Engineering",
     "23": "Facilities Management",
     "24": "Fashion Design",
     "25": "Finance / Economics",
     "26": "Financial Services",
     "27": "Government Sector",
     "28": "Graphic Design",
     "29": "Healthcare / Medical / Hospital",
     "30": "Hospitality / Tourism",
     "31": "Industrial",
     "32": "Insurance",
     "33": "Interior Design",
     "34": "Journalism",
     "35": "Laboratory",
     "36": "Security",
     "37": "Legal",
     "38": "Management",
     "39": "Manufacturing and Production",
     "40": "Marketing",
     "41": "Mechanical",
     "42": "Oil and Gas",
     "43": "Public Relations",
     "44": "Publishing",
     "45": "Procurement / Warehousing",
     "46": "Research",
     "47": "Real Estate",
     "48": "Safety / Environment",
     "49": "Sales",
     "50": "Science",
     "51": "Sports and Recreation",
     "52": "Support Services",
     "53": "Technical / Maintenance",
     "54": "Telecommunication",
     "55": "Translation",
     "56": "Transportation",
     "57": "Equestrian"
}
export const industryFieldAR = {
    "1": "المحاسبة / التدقيق",
    "2": "إدارة",
    "3": "إعلان",
    "4": "زراعة",
    "5": "خطوط الطيران / الطيران",
    "6": "هندسة معمارية",
    "7": "فنون / ترفيه / إعلام",
    "8": "السيارات",
    "9": "مصرفي",
     "10": "التكنولوجيا الحيوية",
     "11": "دعم الأعمال",
     "12": "تقديم الطعام / الغذاء الخدمات",
     "13": "المجتمع / الخدمة الاجتماعية",
     "14": "نظم المعلومات",
     "15": "إنشاءات",
     "16": "خدمات استشارية",
     "17": "العقود / المشتريات",
     "18": "خدمة الزبائن",
     "19": "توزيع و تسويق",
     "20": "التعليم والتدريب ومكتبة",
     "21": "طاقة",
     "22": "هندسة",
     "23": "إدارة المرافق",
     "24": "تصميم الأزياء",
     "25": "المالية / الاقتصاد",
     "26": "الخدمات المالية",
     "27": "القطاع الحكومي",
     "28": "التصميم الجرافيكي",
     "29": "الرعاية الصحية / طب / مستشفى",
     "30": "الضيافة / السياحة",
     "31": "الصناعة",
     "32": "تأمين",
     "33": "هندسة ديكور",
     "34": "صحافة",
     "35": "مختبر",
     "36": "أمن",
     "37": "قانوني",
     "38": "إدارة",
     "39": "التصنيع والانتاج",
     "40": "تسويق",
     "41": "ميكانيكي",
     "42": "النفط والغاز",
     "43": "العلاقات العامة",
     "44": "نشر",
     "45": "المشتريات / المخازن",
     "46": "بحث",
     "47": "عقار",
     "48": "صيانة/ الأمن",
     "49": "مبيعات",
     "50": "علم",
     "51": "الرياضة والترفيه",
     "52": "خدمات الدعم",
     "53": "التقنية / صيانة",
     "54": "اتصالات",
     "55": "ترجمة",
     "56": "نقل",
     "57": "فروسي"
}

export const BEAT_EN = {
    '1': "Education",
    '2': "Health",
    '3': "Sports",
    '4': "Art and Culture",
    '5': "Politics",
    '6': "Sustainability",
    '7': "Technology",
    '8': "Other"
}
export const BEAT_AR = {
    '1': "تعليم",
    '2': "صحة",
    '3': "رياضة",
    '4': "فن وثقافة",
    '5': "سياسة",
    '6': "استدامة",
    '7': "تكنولوجيا",
    '8': "أخرى"
}