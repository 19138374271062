import React from 'react';
import { Grid, Row, Column, FormLabel, Checkbox } from "carbon-components-react";
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactHtmlParser from "react-html-parser";

/* @ts-ignore */
const Review = (props: { form?: any; data: any; intl: any }) => {
    /* @ts-ignore */
    var steps = []

    steps = Object.entries(props.data)
          .filter(([k]) => k.startsWith('step')).sort()
          .map(([_, v]) => v);   

    /* @ts-ignore */
    const buildRows = (items, i) => {
        return (
            <div>
                <strong>
                    <FormattedMessage id={`${ props.form ? props.form + '.' : '' }step${i + 1}`} />
                </strong>
                { buildColumns(items) }
            </div>
        )
    }
    
    /* @ts-ignore */
    const isCollection = (value) => {
        if(value != null && typeof value == 'object') {
            return true
        }
        return false
      }

    /* @ts-ignore */
    const buildCollections = (items) => {
        /* @ts-ignore */
        let fields = [];
        /* eslint-disable-next-line */
        for (let [name, value] of Object.entries(items)) {
            fields.push(
                    <Column lg={{ span: isCollection(value) ? 12 : 3 }} className="fields collection-row">
                        { buildColumns(value) }
                    </Column>
            );
        }
        return <Row>{ fields }</Row>;
    }  

    /* @ts-ignore */
    const buildColumns = (items) => {
        /* @ts-ignore */
        let fields = [];
        let all_items = items ? items : []
        for (let [key, value] of Object.entries(all_items).sort((a, b) => {
            return a[0].localeCompare(b[0]);
        })) {
            (typeof value !== 'undefined') && fields.push(
                <Column lg={{ span: isCollection(value) ? 12 : 3 }} className={`fields ${isCollection(value) && 'collection'}`}>
                    {
                        !isCollection(value) && (
                            <FormLabel className="field_label">
                                <FormattedMessage id={`field.${key.slice(2)}`} />
                            </FormLabel>
                        )
                    }
                    <div>
                        {
                            isCollection(value) ? buildCollections(value) : `${value}`
                        }
                    </div>
                </Column>
            );
        }
        return <Row>{ fields }</Row>;
    }

    return (
        <Grid className="review_fields no-gutter">
            {
                /* @ts-ignore */
                steps.map((items, i)=> buildRows(items, i))
            }
            {
                props.form === 'balagh' && (
                    <Row>
                        <Column lg={{ span: 8, offset: 0 }}>
                        <Checkbox
                            id="terms"
                            name="terms"
                            labelText={ ReactHtmlParser(props.intl.formatMessage({ id: "field.terms" })) }
                            required={props.intl.formatMessage({ id: "field.required" })}
                            title={"terms"}
                            >
                        </Checkbox>
                        </Column>
                    </Row>
                )
            }
        </Grid>
    )
}

export default injectIntl(Review);