import React, { useEffect } from 'react';
import 'carbon-components/css/carbon-components.min.css';
import { BLOB_ENDPOINT, BALAGH_FILE_CONTAINER, QATARIZATION_FILE_CONTAINER } from '../utils/Constants'
import { useField } from '@formiz/core'
import { TextInput, TextArea, Select, SelectItem, Checkbox, RadioButton, FileUploaderButton, FormGroup, SelectableTile, TileGroup, RadioTile, FormLabel, DatePicker, DatePickerInput } from "carbon-components-react";
import { injectIntl, FormattedMessage } from 'react-intl';
// @ts-ignore
import MultiSelect from "react-multi-select-component"

const storage = require('azure-storage/browser/azure-storage.blob.export');
const blobService = storage.createBlobService(BLOB_ENDPOINT);

/* @ts-ignore */
export const InputField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, disabled } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <TextInput
          id={id}
          type="text"
          value={value ?? ''}
          invalid={showError}
          invalidText={errorMessage}
          className="bx--text-input minwidth-0 no-mgn-btm"
          labelText={label}
          onChange={e => setValue(e.target.value)}
          onBlur={() => setIsTouched(true)}
          placeholder={label}
          disabled={disabled}
          >
        </TextInput>
    </div>
  )
}

/* @ts-ignore */
export const CheckboxField = (props) => {
  const { isValid, isSubmitted, setValue, value } = useField(props)
  const { label, onChange, name, id, checked } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  useEffect(() => {
    value ? setValue(checked.formatMessage({ id: "Yes" })) : setValue(undefined)
  });

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <Checkbox
          id={id}
          labelText={label}
          onChange={onChange}
          // @ts-ignore
          onClick={e => e.target.checked ? setValue(true) : setValue(undefined) }
          onBlur={(e) => setIsTouched(true)}
          title={name}
        >
      </Checkbox>
    </div>
  )
}

/* @ts-ignore */
export const TextAreaField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, placeholder } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <TextArea
          id={id}
          rows={4}
          value={value ?? ''}
          placeholder={placeholder ?? ''}
          invalid={showError}
          invalidText={errorMessage}
          className="bx--text-input minwidth-0 no-mgn-btm"
          labelText={label}
          onChange={e => setValue(e.target.value)}
          onBlur={(e) => setIsTouched(true)}
          >
        </TextArea>
    </div>
  )
}

/* @ts-ignore */
const SelectField = (props) => {
  /* eslint-disable-next-line */
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, options, defaultSelected, disabled, className, placeholder } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(null)
  const showError = !isValid && (isTouched || isSubmitted)

  let select_options = []
  for (let [key, values] of Object.entries(options)) {
    select_options.push(
      <SelectItem
        disabled={false}
        hidden={false}
        // @ts-ignore
        text={values}
        key={key}
        value={key}
      />
    )
  }

  useEffect(() => {
    // @ts-ignore
    selectedValue && setValue(options[selectedValue])
  });

  // @ts-ignore
  const setSelectValue = (e) => {
    setValue(e)
    setSelectedValue(e)
  }

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
        <Select
          defaultValue={defaultSelected ? defaultSelected : 'placeholder-item'}
          disabled={disabled}
          id={id}
          inline={false}
          invalid={showError}
          invalidText={errorMessage}
          labelText={label || null}
          className={className || null}
          light={false}
          // @ts-ignore
          onChange={e => setSelectValue(e.target.value)}
          onBlur={() => setIsTouched(true)}
        >
          <SelectItem
            disabled
            hidden
            text={ props.intl.formatMessage({ id: "select.default" }) }
            value="placeholder-item"
          />
          {
            select_options
          }
        </Select>
    </div>
  )
}

/* @ts-ignore */
export const RadioField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, options, name, checked } = props
  const [isTouched] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(null)
  const showError = !isValid && (isTouched || isSubmitted)

  let select_options = []
  var index = 0;
  for (let [key, values] of Object.entries(options)) {
    var isChecked = index === 0 && true
    // @ts-ignore
    // index === 0 && setDefaultValue(values)
    select_options.push(
      <RadioButton
        key={key}
        id={key}
        name={name}
        labelText={checked.formatMessage({ id: values })}
        value={key}
        defaultChecked={isChecked}
        // @ts-ignore
        onClick={ e => setSelectValue( key ) }
      />
    )
    index++;
  }

  useEffect(() => {
    selectedValue ? setValue(checked.formatMessage({ id: "field." + selectedValue })) : setValue(checked.formatMessage({ id: "field." + Object.entries(options)[0][0] }))
  });

  // @ts-ignore
  const setSelectValue = (e) => {
    setValue(e)
    setSelectedValue(e)
  }

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <FormGroup
          invalid={false}
          legendText={label}
          message={false}
          messageText=""
        >
        <div className="bx--radio-button-group bx--radio-button-group--label-right">
          {
              select_options
          }
        </div>
      </FormGroup>
      {showError && (
        <div id={`${id}-error`} className="form-feedback">
          { errorMessage }
        </div>
      )}
    </div>
  )
}

/* @ts-ignore */
export const FileUploaderField = (props) => {
  const { label, acceptFileType, name, form } = props
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const [isFileInvalid, setIsFileInvalid] = React.useState(false)
  const [originalFileName, setOriginalFileName] = React.useState(null)
  const showError = !isValid && isSubmitted
  var container = (form === 'qatarization' ? QATARIZATION_FILE_CONTAINER : BALAGH_FILE_CONTAINER);

  const clearFile = () => {
    setValue(undefined)
    setIsFileInvalid(false)
  }

  const resetFile = () => {
    setValue(undefined)
    setIsFileInvalid(true)
  }
  
  /* @ts-ignore */
  const handleFileUpload = (e) => {
    var fileInputElement = document.getElementById("id1");
    
    // @ts-ignore
    var file_name = fileInputElement.files[0].name.substr(0, fileInputElement.files[0].name.lastIndexOf("."))
    // @ts-ignore
    var file_ext = fileInputElement.files[0].name.split('.').pop();

    var new_filename = `${file_name}-${Date.now()}.${file_ext}`

    /* @ts-ignore */
    var FileSize = fileInputElement.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 10) {
      /* @ts-ignore */
        resetFile()
        setIsFileInvalid(true)
        return false;
    } else {
        // @ts-ignore
        setValue(new_filename)
        // @ts-ignore
        setOriginalFileName(fileInputElement.files[0].name)
        setIsFileInvalid(false)
    }

    /* @ts-ignore */
    blobService.createBlockBlobFromBrowserFile(container, new_filename, fileInputElement.files[0], err => {
        if (err) {
            /* @ts-ignore */
            reject(err);
        } else {
            console.log("file uploaded successfully!")
        }
    });
  }
  
  return (
    <div id="file__container" className={`bx--file__container ${isFileInvalid ? "invalidFile" : null}`}>
      <FormGroup
        legendText={label}
        className={showError ? 'file_error' : ''}
        >
          <FileUploaderButton
            labelText={value ? originalFileName : <FormattedMessage id={`upload_placeholder`} />}
            accept={acceptFileType}
            /* @ts-ignore */
            name={name}
            buttonKind={'tertiary'}
            /* @ts-ignore */
            onChange={(e) => handleFileUpload(e)} 
            /* @ts-ignore */
            onDelete={e => setValue(null)}
            multiple={false}
            listFiles={true}
            size="field"
            fileInvalid={isFileInvalid}
      />
      {value &&
        <span className="bx--file__state-container"><button onClick={() => { clearFile() }} aria-label="Clear file" className="bx--file-close" type="button" tabIndex={0}><svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 32 32" aria-hidden="true"><path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path></svg></button></span>
      }
      {isFileInvalid && 
        <>
          <div id={'file_size'} className="bx--form-requirement">{ <FormattedMessage id={`file_size_limit_exceeded`} /> }
          </div>
        </>
      }
      {showError && (
        <div id={`${id}-error`} className="bx--form-requirement">
          { errorMessage }
        </div>
      )}
      </FormGroup>
    </div>
  )
}

/* @ts-ignore */
export const TileField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, disabled, title, className, onChange, group } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)

  return (
    <div className="tiles">
        <FormLabel> { title } </FormLabel>
        <SelectableTile
          id={id}
          light={false}
          // @ts-ignore
          name={group}
          value={label}
          // @ts-ignore
          onChange={e => e.target.checked ? setValue(label) : setValue(null) }
          onClick={onChange}
          selected={false}
          tabIndex={0}
          title="Preferred language"
          className={className}
        >
          { label }
      </SelectableTile>
      {showError && (
        <div id={`${id}-error`} className="bx--form-requirement">
          { errorMessage }
        </div>
      )}
    </div>
    
  )
}

/* @ts-ignore */
export const RadioTileField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, options, name, checked } = props
  const [isTouched] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(null)
  const showError = !isValid && (isTouched || isSubmitted)

  let select_options = []
  var index = 0;
  for (let [key, values] of Object.entries(options)) {
    var isChecked = index === 0 && true
    // @ts-ignore
    // index === 0 && setDefaultValue(values)
    select_options.push(
      <RadioTile
        key={key}
        id={key}
        name={name}
        value={key}
        defaultChecked={isChecked}
        // @ts-ignore
        onClick={ e => setSelectValue( key ) }
        className={`radio_tile ${index == Object.keys(options).length - 1 && 'last'} ${index === 0 && 'first'}`}
      >
        { checked.formatMessage({ id: values }) }
      </RadioTile>
    )
    index++;
  }

  useEffect(() => {
    selectedValue ? setValue(checked.formatMessage({ id: "field." + selectedValue })) : setValue(checked.formatMessage({ id: "field." + Object.entries(options)[0][0] }))
  });

  // @ts-ignore
  const setSelectValue = (e) => {
    setValue(e)
    setSelectedValue(e)
  }

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <TileGroup
          name={name}
          legend={label}
          defaultSelected={Object.entries(options)[0][0]}
          className="bx--tile-group"
        >
        { select_options }
      </TileGroup>
      {showError && (
        <div id={`${id}-error`} className="form-feedback">
          { errorMessage }
        </div>
      )}
    </div>
  )
}

/* @ts-ignore */
export const DatePickerField = (props) => {
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, disabled, className, required, maxDate, currLang } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const [language, setLanguage] = React.useState(currLang)
  const showError = !isValid && (isTouched || isSubmitted)

  useEffect(() => {
    value && setValue(value)
    // @ts-ignore
    setLanguage('"' + currLang + '"')
  });

  // @ts-ignore
  const setDate = (e) => {
    var d = new Date(e);
    var dt_month = d.getMonth() + 1;
    var dt_day = d.getDate();
    var dt_year = d.getFullYear();
    var dt_date = dt_month + '/' + dt_day + '/' + dt_year;

    setValue(dt_date)
  }

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <DatePicker
        dateFormat="m/d/Y"
        datePickerType="single"
        id="date-picker"
        className={className}
        light={false}
        // @ts-ignore
        locale={language}
        value={value}
        // @ts-ignore
        onChange={e => setDate(e)}
        onClose={function noRefCheck(){}}
        short={false}
        maxDate={maxDate}
      >
        <DatePickerInput
          // @ts-ignore
          className={className}
          disabled={false}
          iconDescription="Icon description"
          id={id}
          invalid={required ? typeof value == "string" && value.indexOf('/') > -1 ? false && setValue(null) : isTouched || isSubmitted && true : false}
          // @ts-ignore
          invalidText={<FormattedMessage id={`field.required`} />}
          labelText={label}
          openCalendar={function noRefCheck(){}}
          pattern="d{1,2}/d{4}"
          placeholder="mm/dd/yyyy"
          size={undefined}
          type="text"
          value={value}
        />
      </DatePicker>
    </div>
  )
}

/* @ts-ignore */
export const MultiSelectField = (props) => {
  /* eslint-disable-next-line */
  const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props)
  const { label, options, searchPlaceholder } = props
  const [isTouched, setIsTouched] = React.useState(false)
  const showError = !isValid && (isTouched || isSubmitted)
  const [selected, setSelected] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState(null)
  const [defaultText, setDefaultText] = React.useState(null);

  useEffect(() => {
    // @ts-ignore
    var items = []
    setDefaultText(label)
    // @ts-ignore
    selectedValue && setValue(options[selectedValue])
    // @ts-ignore
    // selected && console.log(selected)

    // @ts-ignore
    let yFilter = selected.map(itemY => { return itemY.value; });
    // @ts-ignore
    let filteredX = options.filter(itemX => yFilter.includes(itemX.value));

    // @ts-ignore
    filteredX.forEach(item =>  {
      // @ts-ignore
      items.push(item.label)
    })
    // @ts-ignore
    setValue(items.join(", "))
  });

  // @ts-ignore
  const customValueRenderer = (selected, _options) => {
    return selected.length
    // @ts-ignore
      ? selected.length + " " + defaultText
      : defaultText;
  };

  // @ts-ignore
  const setSelectValue = (e) => {
    setSelected(e)
     // @ts-ignore
     var items = []
     // @ts-ignore
     var keys = []
     // @ts-ignore
     e.forEach(item =>  {
       // @ts-ignore
       items.push(item.label)
       // @ts-ignore
       keys.push(item.value)
     })
     // @ts-ignore
     setValue(items.join(", "))
     // @ts-ignore
     setSelectedValue(keys)
  }

  const overrideStrings = {
    "search": searchPlaceholder
  }

  return (
    <div className={`form-group ${(showError) ? 'is-error' : ''}`}>
      <FormGroup
        legendText={label}>
        <MultiSelect
        options={options}
        value={selected}
        // @ts-ignore
        onChange={e => setSelectValue(e)}
        labelledBy={"Select"}
        hasSelectAll={false}
        // @ts-ignore
        valueRenderer={customValueRenderer}
        // @ts-ignore
        overrideStrings={overrideStrings}
      />
      {showError && (
        <div id={`${id}-error`} className="bx--form-requirement">
          { errorMessage }
        </div>
      )}
      </FormGroup>
    </div>
  )
}

export default injectIntl(SelectField);