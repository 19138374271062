import { BALAGH_FORM_ENDPOINT, INTERVIEW_FORM_ENDPOINT, MEDIAINQUIRIES_FORM_ENDPOINT, MAILINGLIST_FORM_ENDPOINT, MULTIMEDIA_FORM_ENDPOINT, VISIT_FORM_ENDPOINT, PHOTOGRAPHY_FORM_ENDPOINT, QATARIZATION_FORM_ENDPOINT, MEDIATOURS_FORM_ENDPOINT } from '../utils/Constants'

/* @ts-ignore */
const Submit = async (form, data) => {
    
    let ENDPOINT: any
    if(form === 'balagh') {
        ENDPOINT = BALAGH_FORM_ENDPOINT
    } else if(form === 'interview') {
        ENDPOINT = INTERVIEW_FORM_ENDPOINT
    } else if(form === 'mediainquiries') {
        ENDPOINT = MEDIAINQUIRIES_FORM_ENDPOINT
    } else if(form === 'mailinglist') {
        ENDPOINT = MAILINGLIST_FORM_ENDPOINT
    } else if(form === 'multimedia') {
        ENDPOINT = MULTIMEDIA_FORM_ENDPOINT
    } else if(form === 'visit') {
        ENDPOINT = VISIT_FORM_ENDPOINT
    } else if(form === 'photography') {
        ENDPOINT = PHOTOGRAPHY_FORM_ENDPOINT
    } else if(form === 'qatarization') {
        ENDPOINT = QATARIZATION_FORM_ENDPOINT
    } else if(form === 'mediatours') {
        ENDPOINT = MEDIATOURS_FORM_ENDPOINT
    }

    let result = await (
        await (
            fetch(ENDPOINT, { 
                method: 'POST', 
                body: JSON.stringify(data), 
                headers: {'Content-Type': 'application/json'}
                })
                .then(response => ({
                    status: response.status
                })
                ).then(res => {
                    return res;
                })
        ).catch(err => {
        })
    )
    return result;
}

export default Submit;