import React, { useContext, useEffect } from 'react'
import { Header as SiteHeader, HeaderName } from "carbon-components-react";
import DarkModeToggle from '../utils/DarkModeToggle';
import LanguageContext from "../utils/LanguageContext";
import { injectIntl } from 'react-intl';

const Header = (props: { currentForm: any, currLang: any, intl: any }) => {

  let { language, setLanguage } = useContext(LanguageContext);
  const nextLocale = language === "en" ? "ar" : "en";

  /* @ts-ignore */
  const selectLanguage = (e) => {
    e.preventDefault()
    /* @ts-ignore */
    setLanguage(nextLocale)
  }

  useEffect(
      () => {
        if(props.currentForm.isFirstStep) {
          document.body.classList.add("intro-bg")
        } else {
          document.body.classList.remove("intro-bg")
          document.body.classList.add("light-bg")
        }
      }
  );

  return (
    <SiteHeader aria-label={ props.intl.formatMessage({ id: "site.name" }) }>
      <HeaderName href="https://forms.qf.org.qa" prefix="">
        { props.intl.formatMessage({ id: "site.name" }) }
      </HeaderName>
      {
        <span className="headerWrapper">
          <a href="/" className="language" onClick={(e) => selectLanguage(e)}>
            { props.currLang === 'en' ? "العربية" : "English" }
          </a>
          <DarkModeToggle />
        </span>
      }
    </SiteHeader>
  )
}

export default injectIntl(Header);