  
import React, { useState } from 'react';
import useDarkMode from 'use-dark-mode';
import { Light20, Moon20 } from '@carbon/icons-react';

import * as styles from './DarkModeToggle.module.scss';

const darkProgressIndicator = () => {
	var indicator = typeof document !== 'undefined' && document.getElementById('gatsby-plugin-page-progress');
	if (indicator) indicator.style.backgroundColor = '#6BCDB2';
};

const lightProgressIndicator = () => {
	var indicator = typeof document !== 'undefined' && document.getElementById('gatsby-plugin-page-progress');
	if (indicator) indicator.style.backgroundColor = '#114538';
};

const DarkModeToggle = () => {
	const [mode, setMode] = useState(false);
	const { value } = useDarkMode(false);
	const darkMode = useDarkMode(mode);
	/* @ts-ignore */
	const toggleTheme = event => {
		if (event) {
			event.preventDefault();
		}
		var target = event.currentTarget.parentNode
		if ( target.className.indexOf('sun_animated') > -1 ) {
			target.className = 'moon_animated';
		} else {
			target.className = 'sun_animated';
		}
		setMode(!mode);
	};

	if (typeof window !== 'undefined' && localStorage.getItem('darkMode') === 'true') {
		typeof window !== 'undefined' && localStorage.setItem('progressBarColor', '#6BCDB2');
		darkProgressIndicator();
	} else {
		lightProgressIndicator();
		typeof document !== 'undefined' && localStorage.setItem('progressBarColor', '#114538');
	}

	return (
    /* @ts-ignore */
		<div className={styles.wrapper}>
			<button type="button" onClick={darkMode.toggle} tabIndex={0}>
        {
        /* @ts-ignore */
        value ? <Light20 onClick={toggleTheme} onTab={toggleTheme} /> : <Moon20 onClick={toggleTheme} onTab={toggleTheme} />}
			</button>
		</div>
	);
};

export default DarkModeToggle;