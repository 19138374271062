export const COUNTRIES_EN = {
    "AD": "Andorra",
    "AF": "Afghanistan",
    "AG": "Antigua and Barbuda",
    "AI": "Anguilla",
    "AL": "Albania",
    "AM": "Armenia",
    "AO": "Angola",
    "AQ": "Antarctica",
    "AR": "Argentina",
    "AS": "American Samoa",
    "AT": "Austria",
    "AU": "Australia",
    "AW": "Aruba",
    "AX": "Aland Islands",
    "AZ": "Azerbaijan",
    "BA": "Bosnia and Herzegovina",
    "BB": "Barbados",
    "BD": "Bangladesh",
    "BE": "Belgium",
    "BF": "Burkina Faso",
    "BG": "Bulgaria",
    "BH": "Bahrain",
    "BI": "Burundi",
    "BJ": "Benin",
    "BL": "Saint Barthelemy",
    "BM": "Bermuda",
    "BN": "Brunei",
    "BO": "Bolivia",
    "BQ": "Bonaire, Saint Eustatius and Saba ",
    "BR": "Brazil",
    "BS": "Bahamas",
    "BT": "Bhutan",
    "BV": "Bouvet Island",
    "BW": "Botswana",
    "BY": "Belarus",
    "BZ": "Belize",
    "CA": "Canada",
    "CC": "Cocos Islands",
    "CD": "Democratic Republic of the Congo",
    "CF": "Central African Republic",
    "CG": "Republic of the Congo",
    "CH": "Switzerland",
    "CI": "Ivory Coast",
    "CK": "Cook Islands",
    "CL": "Chile",
    "CM": "Cameroon",
    "CN": "China",
    "CO": "Colombia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CV": "Cape Verde",
    "CW": "Curacao",
    "CX": "Christmas Island",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DE": "Germany",
    "DJ": "Djibouti",
    "DK": "Denmark",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "DZ": "Algeria",
    "EC": "Ecuador",
    "EE": "Estonia",
    "EG": "Egypt",
    "EH": "Western Sahara",
    "ER": "Eritrea",
    "ES": "Spain",
    "ET": "Ethiopia",
    "FI": "Finland",
    "FJ": "Fiji",
    "FK": "Falkland Islands",
    "FM": "Micronesia",
    "FO": "Faroe Islands",
    "FR": "France",
    "GA": "Gabon",
    "GB": "United Kingdom",
    "GD": "Grenada",
    "GE": "Georgia",
    "GF": "French Guiana",
    "GG": "Guernsey",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Greenland",
    "GM": "Gambia",
    "GN": "Guinea",
    "GP": "Guadeloupe",
    "GQ": "Equatorial Guinea",
    "GR": "Greece",
    "GS": "South Georgia and the South Sandwich Islands",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HK": "Hong Kong",
    "HM": "Heard Island and McDonald Islands",
    "HN": "Honduras",
    "HR": "Croatia",
    "HT": "Haiti",
    "HU": "Hungary",
    "ID": "Indonesia",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IN": "India",
    "IO": "British Indian Ocean Territory",
    "IQ": "Iraq",
    "IR": "Iran",
    "IS": "Iceland",
    "IT": "Italy",
    "JE": "Jersey",
    "JM": "Jamaica",
    "JO": "Jordan",
    "JP": "Japan",
    "KE": "Kenya",
    "KG": "Kyrgyzstan",
    "KH": "Cambodia",
    "KI": "Kiribati",
    "KM": "Comoros",
    "KN": "Saint Kitts and Nevis",
    "KP": "North Korea",
    "KR": "South Korea",
    "KW": "Kuwait",
    "KY": "Cayman Islands",
    "KZ": "Kazakhstan",
    "LA": "Laos",
    "LB": "Lebanon",
    "LC": "Saint Lucia",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Liberia",
    "LS": "Lesotho",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "LV": "Latvia",
    "LY": "Libya",
    "MA": "Morocco",
    "MC": "Monaco",
    "MD": "Moldova",
    "ME": "Montenegro",
    "MF": "Saint Martin",
    "MG": "Madagascar",
    "MH": "Marshall Islands",
    "MK": "Macedonia",
    "ML": "Mali",
    "MM": "Myanmar",
    "MN": "Mongolia",
    "MO": "Macao",
    "MP": "Northern Mariana Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Mauritius",
    "MV": "Maldives",
    "MW": "Malawi",
    "MX": "Mexico",
    "MY": "Malaysia",
    "MZ": "Mozambique",
    "NA": "Namibia",
    "NC": "New Caledonia",
    "NE": "Niger",
    "NF": "Norfolk Island",
    "NG": "Nigeria",
    "NI": "Nicaragua",
    "NL": "Netherlands",
    "NO": "Norway",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "New Zealand",
    "OM": "Oman",
    "PA": "Panama",
    "PE": "Peru",
    "PF": "French Polynesia",
    "PG": "Papua New Guinea",
    "PH": "Philippines",
    "PK": "Pakistan",
    "PL": "Poland",
    "PM": "Saint Pierre and Miquelon",
    "PN": "Pitcairn",
    "PR": "Puerto Rico",
    "PS": "Palestinian Territory",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paraguay",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RS": "Serbia",
    "RU": "Russia",
    "RW": "Rwanda",
    "SA": "Saudi Arabia",
    "SB": "Solomon Islands",
    "SC": "Seychelles",
    "SD": "Sudan",
    "SE": "Sweden",
    "SG": "Singapore",
    "SH": "Saint Helena",
    "SI": "Slovenia",
    "SJ": "Svalbard and Jan Mayen",
    "SK": "Slovakia",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SO": "Somalia",
    "SR": "Suriname",
    "SS": "South Sudan",
    "ST": "Sao Tome and Principe",
    "SV": "El Salvador",
    "SX": "Sint Maarten",
    "SY": "Syria",
    "SZ": "Swaziland",
    "TC": "Turks and Caicos Islands",
    "TD": "Chad",
    "TF": "French Southern Territories",
    "TG": "Togo",
    "TH": "Thailand",
    "TJ": "Tajikistan",
    "TK": "Tokelau",
    "TL": "East Timor",
    "TM": "Turkmenistan",
    "TN": "Tunisia",
    "TO": "Tonga",
    "TR": "Turkey",
    "TT": "Trinidad and Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TZ": "Tanzania",
    "UA": "Ukraine",
    "UG": "Uganda",
    "AE": "United Arab Emirates",
    "UM": "United States Minor Outlying Islands",
    "US": "United States",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VA": "Vatican",
    "VC": "Saint Vincent and the Grenadines",
    "VE": "Venezuela",
    "VG": "British Virgin Islands",
    "VI": "U.S. Virgin Islands",
    "VN": "Vietnam",
    "VU": "Vanuatu",
    "WF": "Wallis and Futuna",
    "WS": "Samoa",
    "XK": "Kosovo",
    "YE": "Yemen",
    "YT": "Mayotte",
    "ZA": "South Africa",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
};

export const COUNTRIES_AR = {
    "AD": "أندورا",
    "AF": "أفغانستان",
    "AG": "أنتيجوا وبربودا",
    "AI": "أنجويلا",
    "AL": "ألبانيا",
    "AM": "أرمينيا",
    "AO": "أنجولا",
    "AQ": "القطب الجنوبي",
    "AR": "الأرجنتين",
    "AS": "ساموا الأمريكية",
    "AT": "النمسا",
    "AU": "أستراليا",
    "AW": "آروبا",
    "AX": "جزر أولان",
    "AZ": "أذربيجان",
    "BA": "البوسنة والهرسك",
    "BB": "بربادوس",
    "BD": "بنجلاديش",
    "BE": "بلجيكا",
    "BF": "بوركينا فاسو",
    "BG": "بلغاريا",
    "BH": "البحرين",
    "BI": "بوروندي",
    "BJ": "بنين",
    "BL": "سان بارتيلمي",
    "BM": "برمودا",
    "BN": "بروناي",
    "BO": "بوليفيا",
    "BQ": "بونير",
    "BR": "البرازيل",
    "BS": "الباهاما",
    "BT": "بوتان",
    "BV": "جزيرة بوفيه",
    "BW": "بتسوانا",
    "BY": "روسيا البيضاء",
    "BZ": "بليز",
    "CA": "كندا",
    "CC": "جزر كوكوس",
    "CD": "جمهورية الكونغو الديمقراطية",
    "CF": "جمهورية افريقيا الوسطى",
    "CG": "الكونغو - برازافيل",
    "CH": "سويسرا",
    "CI": "ساحل العاج",
    "CK": "جزر كوك",
    "CL": "شيلي",
    "CM": "الكاميرون",
    "CN": "الصين",
    "CO": "كولومبيا",
    "CR": "كوستاريكا",
    "CU": "كوبا",
    "CV": "الرأس الأخضر",
    "CW": "كوراساو",
    "CX": "جزيرة الكريسماس",
    "CY": "قبرص",
    "CZ": "جمهورية التشيك",
    "DE": "ألمانيا",
    "DJ": "جيبوتي",
    "DK": "الدانمرك",
    "DM": "دومينيكا",
    "DO": "جمهورية الدومينيك",
    "DZ": "الجزائر",
    "EC": "الاكوادور",
    "EE": "استونيا",
    "EG": "مصر",
    "EH": "الصحراء الغربية",
    "ER": "اريتريا",
    "ES": "أسبانيا",
    "ET": "اثيوبيا",
    "FI": "فنلندا",
    "FJ": "فيجي",
    "FK": "جزر فوكلاند",
    "FM": "ميكرونيزيا",
    "FO": "جزر فارو",
    "FR": "فرنسا",
    "GA": "الجابون",
    "GB": "المملكة المتحدة",
    "GD": "جرينادا",
    "GE": "جورجيا",
    "GF": "غويانا",
    "GG": "غيرنزي",
    "GH": "غانا",
    "GI": "جبل طارق",
    "GL": "جرينلاند",
    "GM": "غامبيا",
    "GN": "غينيا",
    "GP": "جوادلوب",
    "GQ": "غينيا الاستوائية",
    "GR": "اليونان",
    "GS": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    "GT": "جواتيمالا",
    "GU": "جوام",
    "GW": "غينيا بيساو",
    "GY": "غيانا",
    "HK": "هونج كونج الصينية",
    "HM": "جزيرة هيرد وماكدونالد",
    "HN": "هندوراس",
    "HR": "كرواتيا",
    "HT": "هايتي",
    "HU": "المجر",
    "ID": "اندونيسيا",
    "IE": "أيرلندا",
    "IM": "جزيرة مان",
    "IN": "الهند",
    "IO": "المحيط الهندي البريطاني",
    "IQ": "العراق",
    "IR": "ايران",
    "IS": "أيسلندا",
    "IT": "ايطاليا",
    "JE": "جيرسي",
    "JM": "جامايكا",
    "JO": "الأردن",
    "JP": "اليابان",
    "KE": "كينيا",
    "KG": "قرغيزستان",
    "KH": "كمبوديا",
    "KI": "كيريباتي",
    "KM": "جزر القمر",
    "KN": "سانت كيتس ونيفيس",
    "KP": "كوريا الشمالية",
    "KR": "كوريا الجنوبية",
    "KW": "الكويت",
    "KY": "جزر الكايمن",
    "KZ": "كازاخستان",
    "LA": "لاوس",
    "LB": "لبنان",
    "LC": "سانت لوسيا",
    "LI": "ليختنشتاين",
    "LK": "سريلانكا",
    "LR": "ليبيريا",
    "LS": "ليسوتو",
    "LT": "ليتوانيا",
    "LU": "لوكسمبورج",
    "LV": "لاتفيا",
    "LY": "ليبيا",
    "MA": "المغرب",
    "MC": "موناكو",
    "MD": "مولدافيا",
    "ME": "الجبل الأسود",
    "MF": "سانت مارتين",
    "MG": "مدغشقر",
    "MH": "جزر المارشال",
    "MK": "مقدونيا",
    "ML": "مالي",
    "MM": "ميانمار",
    "MN": "منغوليا",
    "MO": "ماكاو الصينية",
    "MP": "جزر ماريانا الشمالية",
    "MQ": "مارتينيك",
    "MR": "موريتانيا",
    "MS": "مونتسرات",
    "MT": "مالطا",
    "MU": "موريشيوس",
    "MV": "جزر الملديف",
    "MW": "ملاوي",
    "MX": "المكسيك",
    "MY": "ماليزيا",
    "MZ": "موزمبيق",
    "NA": "ناميبيا",
    "NC": "كاليدونيا الجديدة",
    "NE": "النيجر",
    "NF": "جزيرة نورفوك",
    "NG": "نيجيريا",
    "NI": "نيكاراجوا",
    "NL": "هولندا",
    "NO": "النرويج",
    "NP": "نيبال",
    "NR": "نورو",
    "NU": "نيوي",
    "NZ": "نيوزيلاندا",
    "OM": "عمان",
    "PA": "بنما",
    "PE": "بيرو",
    "PF": "بولينيزيا الفرنسية",
    "PG": "بابوا غينيا الجديدة",
    "PH": "الفيلبين",
    "PK": "باكستان",
    "PL": "بولندا",
    "PM": "سانت بيير وميكولون",
    "PN": "بتكايرن",
    "PR": "بورتوريكو",
    "PS": "فلسطين",
    "PT": "البرتغال",
    "PW": "بالاو",
    "PY": "باراجواي",
    "QA": "قطر",
    "RE": "روينيون",
    "RO": "رومانيا",
    "RS": "صربيا",
    "RU": "روسيا",
    "RW": "رواندا",
    "SA": "المملكة العربية السعودية",
    "SB": "جزر سليمان",
    "SC": "سيشل",
    "SD": "السودان",
    "SE": "السويد",
    "SG": "سنغافورة",
    "SH": "سانت هيلنا",
    "SI": "سلوفينيا",
    "SJ": "سفالبارد وجان مايان",
    "SK": "سلوفاكيا",
    "SL": "سيراليون",
    "SM": "سان مارينو",
    "SN": "السنغال",
    "SO": "الصومال",
    "SR": "سورينام",
    "SS": "جنوب السودان",
    "ST": "ساو تومي وبرينسيبي",
    "SV": "السلفادور",
    "SX": "سينت مارتن",
    "SY": "سوريا",
    "SZ": "سوازيلاند",
    "TC": "جزر الترك وجايكوس",
    "TD": "تشاد",
    "TF": "المقاطعات الجنوبية الفرنسية",
    "TG": "توجو",
    "TH": "تايلند",
    "TJ": "طاجكستان",
    "TK": "توكيلو",
    "TL": "تيمور الشرقية",
    "TM": "تركمانستان",
    "TN": "تونس",
    "TO": "تونجا",
    "TR": "تركيا",
    "TT": "ترينيداد وتوباغو",
    "TV": "توفالو",
    "TW": "تايوان",
    "TZ": "تانزانيا",
    "UA": "أوكرانيا",
    "UG": "أوغندا",
    "AE": "الامارات العربية المتحدة",
    "UM": "جزر الولايات المتحدة البعيدة الصغيرة",
    "US": "الولايات المتحدة الأمريكية",
    "UY": "أورجواي",
    "UZ": "أوزبكستان",
    "VA": "الفاتيكان",
    "VC": "سانت فنسنت وغرنادين",
    "VE": "فنزويلا",
    "VG": "جزر فرجين البريطانية",
    "VI": "جزر فرجين الأمريكية",
    "VN": "فيتنام",
    "VU": "فانواتو",
    "WF": "جزر والس وفوتونا",
    "WS": "ساموا",
    "XK": "كوسوفو",
    "YE": "اليمن",
    "YT": "مايوت",
    "ZA": "جمهورية جنوب افريقيا",
    "ZM": "زامبيا",
    "ZW": "زيمبابوي"
}