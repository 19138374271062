export const BLOB_ENDPOINT = 'BlobEndpoint=https://qfwebsitecdn.blob.core.windows.net/;QueueEndpoint=https://qfwebsitecdn.queue.core.windows.net/;FileEndpoint=https://qfwebsitecdn.file.core.windows.net/;TableEndpoint=https://qfwebsitecdn.table.core.windows.net/;SharedAccessSignature=sv=2018-03-28&ss=bfqt&srt=sco&sp=rwdlacup&se=2025-01-01T14:48:37Z&st=2019-05-28T06:48:37Z&spr=https,http&sig=28INH7YjuchdbvfsAnRg6nnjP11fjPRtFURm4eSqDu8%3D';
export const BALAGH_FILE_CONTAINER = 'integrityform';
export const QATARIZATION_FILE_CONTAINER = 'qatarizationform';
export const BALAGH_FORM_ENDPOINT = 'https://prod-97.westeurope.logic.azure.com:443/workflows/e8be9173d6104d128f5f2241ee032aba/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=8CSsQB_-vmkv1G6HafIY0v8T7FjyqSrYjhDsJPui9kI';
export const INTERVIEW_FORM_ENDPOINT = 'https://prod-27.westeurope.logic.azure.com:443/workflows/e6b1cb622f234ce6bd00edd881d6aa0d/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kYQsPi0DLP1Fp3On8OOXbYG78hwzaPHZGkBiyBP_N8o';
export const MEDIAINQUIRIES_FORM_ENDPOINT = 'https://prod-135.westeurope.logic.azure.com:443/workflows/c8cf38fa51b64497bb284eb55971d3ee/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_jac29AarFh-7co_E4ngb6lFxi8VGm7Fj9OJ6UsjxoM';
export const MULTIMEDIA_FORM_ENDPOINT = 'https://prod-164.westeurope.logic.azure.com:443/workflows/0e91d39ed4564c08af352f68a08fca67/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=43pT9HlHPdFEXAUCeywxGy-CTCt1ywLYOOrk21wfYxg';
export const MAILINGLIST_FORM_ENDPOINT = 'https://prod-175.westeurope.logic.azure.com:443/workflows/7330824757554d0e8a60f3ca34d360ba/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=fONLF2kpPrbDPbXgKNoOSMeNjBjcRVEVD8gutYVOHlA';
export const PHOTOGRAPHY_FORM_ENDPOINT = 'https://prod-167.westeurope.logic.azure.com:443/workflows/e95007fb72034674926cb24082cac841/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=81gtSU6SVhf9GHYyWbvItBRE63qqE3MILLcRwLMaZSY';
export const VISIT_FORM_ENDPOINT = 'https://prod-43.westeurope.logic.azure.com:443/workflows/a8901139b8164b97b5a64eef65856f3c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_a3oTY5AYO_bXdv2fYjCP66q9v7QlgGe0XCP1z_Hz6I';
export const QATARIZATION_FORM_ENDPOINT = 'https://prod-97.westeurope.logic.azure.com:443/workflows/ffb6c069397e482e915708d2f08e5be2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=DkjIXpZI3Omwca9kOoHeEgVAJbkR-8rl2fv5yKA32aI';
export const MEDIATOURS_FORM_ENDPOINT = 'https://prod-05.westeurope.logic.azure.com:443/workflows/27438feec3064ab18d6903b5581bb0e3/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bwHsJYtk3fqGnmKUhOkSv42IR9T9N-RTVW3ULXloow4'
