import React, { useState } from 'react'
import { Header, Button, ToastNotification, InlineLoading } from "carbon-components-react";
import { ArrowRight16, ArrowLeft16, CheckmarkOutline16, Logout16 } from '@carbon/icons-react';
import { injectIntl } from 'react-intl';
import Submit from '../components/Submit';

const Footer = (props: { form?: any; currentForm: any, currLang: any, data: any, intl: any }) => {

    const [notification, setNotification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [terms, setTerms] = useState(false);

    /* @ts-ignore */
    const verifyNextStep = (e) => {
        if(props.currentForm.isStepValid && props.currentForm.currentStep.name === `${props.form}.step${props.currentForm.steps.length - 3}` && !props.currentForm.isValid) {
            e.preventDefault();
            setError(false)
            setNotification(true)
            setTimeout(function() {
                setNotification(false)
            }, 3000);
        }

        if(props.currentForm.isLastStep) {
            e.preventDefault();
            window.location.href = "https://www.qf.org.qa"
        }
    }

    const showErrorNotification = () => {
        setNotification(true)
        setLoading(false)
        setError(true)
        setTimeout(function() {
            setNotification(false)
        }, 3000);
    }

    const showTermsNotification = () => {
        setNotification(true)
        setLoading(false)
        setError(false)
        setTerms(true)
        setTimeout(function() {
            setNotification(false)
            setTerms(false)
        }, 3000);
    }

    /* @ts-ignore */
    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true)
        if(props.form === 'balagh') {
            var terms = document.getElementById("terms")
            // @ts-ignore
            if(!terms.checked) {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                showTermsNotification()
                return false
            }
        }
        Submit(props.form, props.data).then(response => {
            if (response != null) {
                // @ts-ignore
                if (response.status === 202) {
                    setLoading(false)
                    props.currentForm.nextStep()
                } else {
                    showErrorNotification()
                }
            } else {
                showErrorNotification()
            }
        })
        .catch(err => {
            showErrorNotification()
        })
    }

    return (
        <Header className='footer'>
            {
                notification && (
                    <ToastNotification
                        caption={null}
                        hideCloseButton={true}
                        iconDescription="describes the close button"
                        kind="warning"
                        notificationType="inline"
                        onCloseButtonClick={function noRefCheck(){}}
                        role="alert"
                        statusIconDescription="describes the status icon"
                        subtitle={ error ? props.intl.formatMessage({ id: "validation.submit_error" }) : terms ? props.intl.formatMessage({ id: "validation.terms" }) : props.intl.formatMessage({ id: "validation.incomplete" }) }
                        timeout={0}
                        title={null}
                    />
                )
            }

            {!props.currentForm.isFirstStep && !props.currentForm.isLastStep && (
                <Button
                    className="back-btn"
                    iconDescription="Button icon"
                    kind="tertiary"
                    renderIcon={props.currLang === "ar" ? ArrowRight16 : ArrowLeft16}
                    size="default"
                    tabIndex={0}
                    type="button"
                    onClick={props.currentForm.prevStep}
                >
                    { props.intl.formatMessage({ id: "button.back" }) }
                </Button>
            )}
            {props.currentForm.currentStep?.name === `${props.form}.step${props.currentForm.steps.length - 2}` ? (
                <Button
                    className={`continue-btn ${loading && 'submit-btn' }`}
                    iconDescription="Button icon"
                    kind="primary"
                    renderIcon={!loading && CheckmarkOutline16}
                    size="default"
                    tabIndex={0}
                    /* @ts-ignore */
                    onClick={(e) => { submitForm(e) }}
                    type="submit"
                    disabled={loading || !props.currentForm.isValid}
                >
                    {
                        loading ? <InlineLoading description={ props.intl.formatMessage({ id: "button.submit" }) } /> : props.intl.formatMessage({ id: "button.submit" })
                    }
                </Button>
            ) : (
                <Button
                    className="continue-btn"
                    disabled={/*props.currentForm.isStepValid ? false: true */ false}
                    iconDescription="Button icon"
                    kind="primary"
                    renderIcon={!props.currentForm.isLastStep ? props.currLang === "ar" ? ArrowLeft16 : ArrowRight16 : Logout16}
                    size="default"
                    tabIndex={0}
                    /* @ts-ignore */
                    onClick={(e) => { verifyNextStep(e) }}
                    type="submit"
                >
                    { props.intl.formatMessage({ id: props.currentForm.isFirstStep ? "button.startnow" : !props.currentForm.isLastStep ? "button.continue" : "button.backto_qf" }) }
                </Button>
            )}
        </Header>
    )
}

export default injectIntl(Footer);