import React, { useState, useEffect } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core'
import Review from '../Review'
import SelectField, { InputField, DatePickerField, TileField } from '../Fields'
import { ProgressIndicator, ProgressStep, Grid, Row, Column, FormLabel, Button, Search } from "carbon-components-react";
import { Add16, Delete16 } from '@carbon/icons-react';
// @ts-ignore
import { COUNTRIES_EN, COUNTRIES_AR } from '../../data/Countries'
import { time, timeAR } from '../../data/Options'
import { isEmail, isNumber, isMaxLength } from '@formiz/validations'
import { injectIntl } from 'react-intl';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import { v4 as uuidv4 } from 'uuid';

const defaultCollection = [
  {
    id: uuidv4(),
    name: '',
  },
];

/* @ts-ignore */
const VisitWizard = (props: { currLang: any, intl: any }) => {
  const qfForm = useForm()
  const [collection, setCollection] = useState(defaultCollection);
  const [prefLanguage, setPrefLanguage] = useState(true);
  const [prefTopic, setPrefTopic] = useState(true);
  const [locationRequired, setLocationRequired] = useState(true);
  const [locationEmpty, setLocationEmpty] = useState(true);
  const [isLocationValid, setIsLocationValid] = useState(true);

  var participants: any = [];

  useEffect(() => {
    setCollection(defaultCollection);
  }, [qfForm.resetKey]);

  const addItem = () => {
    setCollection((c) => [
      ...c,
      {
        id: uuidv4(),
        name: ''
      },
    ]);
  };

  /* @ts-ignore */
  const removeItem = (id) => {
    setCollection((c) => c.filter((x) => x.id !== id));
  };

  /* @ts-ignore */
  const handleChange = (event) => {
      // Declare variables
      var input, filter, list, location, a, i, txtValue;
      filter = event.target.value && event.target.value.toUpperCase();
      list = document.getElementById("locations");
      // @ts-ignore
      location = list.getElementsByClassName('location');

      // Loop through all list items, and hide those who don't match the search query
      for (i = 0; i < location.length; i++) {
        a = location[i].getElementsByClassName("bx--tile-content")[0];  
        // @ts-ignore
        txtValue = a.textContent || a.innerText;

        if(!event.target.value) {
          // @ts-ignore
          location[i].style.display = "block";
        } else {
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            // @ts-ignore
            location[i].style.display = "block";
          } else {
            // @ts-ignore
            location[i].style.display = "none";
          }
        }
      }
  }

  const LocationsFilter = () => {
      return (
        <div className="bx--row">
          <div className="bx--col-sm-4 padding-bottom-lg">
            <Search
              id={'filter'}
              labelText={''}
              className="minwidth-0 no-mgn-btm"
              onChange={e => handleChange(e)}
              placeHolderText={props.intl.formatMessage({ id: "field.search_locations" })}>
            </Search>
          </div>
        </div>
      )
  }

  var radioboxes = []
  var allRadioboxes = document.querySelectorAll('input[type=radio]:checked')

  for (var n = 0; n < allRadioboxes.length; n++) {
    /* @ts-ignore */
    radioboxes.push({[allRadioboxes[n].name]: allRadioboxes[n].value})
  }

  var step1 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step1')).sort()
          .map(([_, v]) => v)

  var step2 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step2')).sort()
          .map(([_, v]) => v)

  var step3 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step3')).sort()
          .map(([_, v]) => v)
  
  var step4 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step4')).sort()
          .map(([_, v]) => v)  

  var step5 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step5')).sort()
          .map(([_, v]) => v)         

  // @ts-ignore
  var selected_country = step1[0] && step1[0]['6_country']

  // @ts-ignore
  var pref_time = step3[0] && step3[0]['0_preferred_time']
  // @ts-ignore
  var alt_time = step3[0] && step3[0]['1_preferred_time']

  // @ts-ignore
  step1[0] && delete step1[0]['6_country']

  // @ts-ignore
  step2[0] && delete step2[0]['1_photography'] && delete step2[0]['1_filming'] && delete step2[0]['0_english'] && delete step2[0]['0_arabic']

  // @ts-ignore
  step3[0] && delete step3[0]['0_preferred_time'] && delete step3[0]['1_preferred_time'] && step3[0]['1_alternative_date'] === null && delete step3[0]['1_alternative_date']

  // @ts-ignore
  step4[0] && 
    // @ts-ignore
    delete step4[0]['1_location_option1'] && 
    // @ts-ignore
    delete step4[0]['1_location_option2'] &&
    // @ts-ignore
    delete step4[0]['1_location_option3'] &&
    // @ts-ignore
    delete step4[0]['1_location_option4'] &&
    // @ts-ignore
    delete step4[0]['1_location_option5'] &&
    // @ts-ignore
    delete step4[0]['1_location_option6'] && 
    // @ts-ignore
    delete step4[0]['1_location_option7'] &&
    // @ts-ignore
    delete step4[0]['1_location_option8'] &&
    // @ts-ignore
    delete step4[0]['1_location_option9'] &&
    // @ts-ignore
    delete step4[0]['1_location_option10'] &&
    // @ts-ignore
    delete step4[0]['1_location_option11']

  var checkedValue = []; 
  var inputElements = document.getElementsByName('preferred_visit_language');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedValue.push(inputElements[i].value);
      }
  }

  var checkedTopic = []; 
  var inputElements = document.getElementsByName('require_permission_for');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedTopic.push(inputElements[i].value);
      }
  }

  var checkedLocations = []; 
  var inputElements = document.getElementsByName('confirm_location');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedLocations.push(inputElements[i].value);
      }
  }

  // @ts-ignore
  step1 = step1[0]
  // @ts-ignore
  step2 = step2[0]
  // @ts-ignore
  step3 = step3[0]
  // @ts-ignore
  step4 = step4[0]
  // @ts-ignore
  step5 = step5[0]

  // @ts-ignore
  step1 = {...step1, ['6_country']: selected_country };
  // @ts-ignore
  step2 = {...step2, ['0_preferred_visit_language']: checkedValue.join(', '), ['1_require_permission_for']: checkedTopic.join(', ')};
  // @ts-ignore
  step3 = {...step3, ['0_preferred_time']: pref_time , ['1_preferred_time']: alt_time};
  // @ts-ignore
  step4 = {...step4, ['0_confirm_location']: checkedLocations.join(', \n')};

  // @ts-ignore
  Object.entries(qfForm.values).sort((a, b) => {
    return a[0].localeCompare(b[0]);
  }).filter(function( fields ) {
      // @ts-ignore
      fields[0] === 'step5' && fields.forEach((field, i) => {
          // @ts-ignore
          var items = field.collection && field.collection
          // @ts-ignore
          items && items.forEach((item, i) => {
            participants.push(`Participant: #${i + 1}`)
            // @ts-ignore
            item && Object.keys(item).sort((a, b) => {
              return a[0].localeCompare(b[0]);
            }).map(function(k, n) {
                // @ts-ignore
                participants.push(`${props.intl.formatMessage({ id: `field.${k.slice(2)}` })}: ${ item[k] }`)
                n === (Object.keys(item).length - 1) && participants.push(`\n`)
            })
          })
      });
  });

  participants = participants.join(",").split(",").join("\n")

  let formData = {
    step1,
    step2,
    step3,
    step4,
    step5,
    participants
  }

  /* @ts-ignore */
  const switchStep = (element, step) => {
    element.preventDefault();
    qfForm.goToStep(step.name)
  }

  const preferredLanguage = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('preferred_visit_language');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefLanguage(false)
    } else {
      setPrefLanguage(true)
    }
  };

  const preferredTopic = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByName('require_permission_for');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefTopic(false)
    } else {
      setPrefTopic(true)
    }
  };

  // @ts-ignore
  const setLocation = (e) => {
    var choices = [];
    var els = document.getElementsByName('confirm_location');
    for (var i = 0; i < els.length; i++){
      // @ts-ignore
      if ( els[i].checked ) {
        // @ts-ignore
        choices.push(els[i].value);
      }
    }

    if(choices.length >= 1) {
      setLocationEmpty(false)
    } else {
      setLocationEmpty(true)
    }

    if(choices.length >= 1 && choices.length <= 3) {
      setLocationRequired(false)
    } else {
      setLocationRequired(true)
    }
  }

  useEffect(() => {
    var choices = [];
    var els = document.getElementsByName('confirm_location');
    for (var i = 0; i < els.length; i++){
      // @ts-ignore
      if ( els[i].checked ) {
        // @ts-ignore
        choices.push(els[i].value);
      }
    }

    if(choices.length >= 1) {
      setIsLocationValid(true)
    } else {
      if(!qfForm.isStepSubmitted) {
        setIsLocationValid(true)
      } else {
        setIsLocationValid(false)
      }
    }
  });

  return (
    <Formiz
      connect={qfForm}
      onValidSubmit={null}
    >
      <Header currentForm={qfForm} currLang={props.currLang} />
      <form
        noValidate
        onSubmit={qfForm.submitStep}
        className={'wizard visit'}
      >
        <Grid>
          {
            !qfForm.isLastStep && (
              <Row>
              <Column>
                <FormLabel>
                  <h2>
                    { props.intl.formatMessage({ id: "visit.title" }) }
                  </h2>
                </FormLabel>
              </Column>
            </Row>
            )
          }
          {!qfForm.isFirstStep && !qfForm.isLastStep && (
          <Row>
            <Column lg={{ span: 12, offset: 0 }}>
              {
                /* @ts-ignore */
                <ProgressIndicator spaceEqually={true}>
                  <div className="form__tabs visit">
                  {
                    /* @ts-ignore */
                    qfForm.steps.map((step, i) => (
                      i !== 0 && i !== (qfForm.steps.length - 1) && (
                        /* @ts-ignore */
                        <ProgressStep
                          onClick={(element) => switchStep(element, step)} 
                          label={step.label} 
                          key={step.name}
                          /* @ts-ignore */
                          current={step.name === qfForm.currentStep.name ? true: false} 
                          complete={step.isValid && step.isSubmitted ? true: false} 
                          disabled={ i === (qfForm.steps.length - 2) && !qfForm.isValid ? true : false }
                        />
                      )
                    ))
                    }
                  </div>
                </ProgressIndicator>
              }
            </Column>
          </Row>
          )}
          </Grid>
          {
            /* @ts-ignore */
            <FormizStep
              name="visit.step0"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <p>
                        {
                          props.intl.formatMessage({ id: "visit.intro_text" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
            name="visit.step1"
            label={ props.intl.formatMessage({ id: "form.steps.details" }) }
          >
            <Grid className='margin-md form'>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.1_name"
                  label={ props.intl.formatMessage({ id: "field.name" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.2_mobile"
                  label={ props.intl.formatMessage({ id: "field.mobile" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isNumber(),
                      message: props.intl.formatMessage({ id: "validation.mobile" }),
                    },
                    {
                      rule: isMaxLength(12),
                      message: props.intl.formatMessage({ id: "validation.mobile.maxlength" }),
                    }
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.3_title"
                  label={ props.intl.formatMessage({ id: "field.title" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.4_email"
                  label={ props.intl.formatMessage({ id: "field.email" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isEmail(),
                      message: props.intl.formatMessage({ id: "validation.email" }),
                    },
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.5_company"
                  label={ props.intl.formatMessage({ id: "field.company" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <SelectField
                  name="step1.6_country"
                  label={ props.intl.formatMessage({ id: "field.country" }) }
                  required={props.intl.formatMessage({ id: "select.required" })}
                  options={props.currLang === "ar" ? COUNTRIES_AR : COUNTRIES_EN}
                />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="visit.step2"
          label={ props.intl.formatMessage({ id: "visit.step2" }) }
          >
            <Grid>
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_english`}
                      group="preferred_visit_language"
                      label={ props.intl.formatMessage({ id: "field.english" }) }
                      title={props.intl.formatMessage({ id: "field.preferred_visit_language" })}
                      required={prefLanguage && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_arabic`}
                      group="preferred_visit_language"
                      label={ props.intl.formatMessage({ id: "field.arabic" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.1_photography`}
                      group="require_permission_for"
                      label={ props.intl.formatMessage({ id: "field.photography" }) }
                      title={props.intl.formatMessage({ id: "field.require_permission_for" })}
                      required={prefTopic && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={e => preferredTopic()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.1_filming`}
                      group="require_permission_for"
                      label={ props.intl.formatMessage({ id: "field.filming" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => preferredTopic()}
                    />
                  </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="visit.step3"
          label={ props.intl.formatMessage({ id: "visit.step3" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "field.preferred_date_time" }) }</strong>
                    <div className="margin-top">
                    { props.intl.formatMessage({ id: "field.date_time_info" }) }
                    </div>
                  </FormLabel>
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 3, offset: 0 }}>
                  <DatePickerField 
                    name={`step3.0_preferred_date`}
                    label={ props.intl.formatMessage({ id: "field.preferred_date" }) }
                    required={props.intl.formatMessage({ id: "field.required" })}
                  />
                </Column>
                <Column lg={{ span: 2, offset: 0 }}>
                  <SelectField
                    name={`step3.0_preferred_time`}
                    label={ props.intl.formatMessage({ id: "field.preferred_time" }) }
                    required={props.intl.formatMessage({ id: "select.required" })}
                    options={props.currLang === "ar" ? timeAR : time}
                  />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 3, offset: 0 }}>
                  <DatePickerField 
                    name={`step3.1_alternative_date`}
                    label={ props.intl.formatMessage({ id: "field.alternative_date" }) }
                  />
                </Column>
                <Column lg={{ span: 2, offset: 0 }}>
                  <SelectField
                    name={`step3.1_preferred_time`}
                    label={ props.intl.formatMessage({ id: "field.preferred_time" }) }
                    options={props.currLang === "ar" ? timeAR : time}
                  />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="visit.step4"
          label={ props.intl.formatMessage({ id: "visit.step4" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "field.confirm_location" }) }</strong>
                    <div className="margin-top">
                    { props.intl.formatMessage({ id: "field.location_note" }) }
                    </div>
                  </FormLabel>
                </Column>
              </Row>
              {
                LocationsFilter()
              }
              {!locationEmpty && locationRequired && (
                <div className="bx--form-requirement margin-top-lg">
                  { props.intl.formatMessage({ id: "location_validation" }) }
                </div>
              )}
              {!isLocationValid && (
                <div className="bx--form-requirement margin-top-lg">
                  { props.intl.formatMessage({ id: "location_empty_validation" }) }
                </div>
              )}
              <Row id='locations'>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option1`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option1" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option2`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option2" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option3`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option3" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option4`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option4" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option5`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option5" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option6`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option6" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option7`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option7" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option8`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option8" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option9`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option9" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option10`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option10" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
                <Column className='location' lg={{ span: 4, offset: 0 }}>
                  <TileField
                    name={`step4.1_location_option11`}
                    group="confirm_location"
                    label={ props.intl.formatMessage({ id: "field.location_option11" }) }
                    /* @ts-ignore */
                    onChange={e => setLocation(e)}
                    required={locationRequired}
                  />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="visit.step5"
          label={ props.intl.formatMessage({ id: "visit.step5" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <FormLabel><strong>{ props.intl.formatMessage({ id: "field.add_participants" }) }</strong>
                    <div className="margin-top">
                    { props.intl.formatMessage({ id: "field.add_participants_note" }) }
                    </div>
                  </FormLabel>
                  {collection.map(({ id }, index) => (
                    <div key={id} data-test={`repeater-item[${index}]`}>
                      <Row>
                          <Column lg={{ span: 4, offset: 0 }}>
                            <InputField
                              name={`step5.collection[${index}].0_name`}
                              label={ props.intl.formatMessage({ id: "field.name" }) }
                              required={props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          <Column lg={{ span: 4, offset: 0 }}>
                            <InputField
                              name={`step5.collection[${index}].0_title`}
                              label={ props.intl.formatMessage({ id: "field.title" }) }
                              required={props.intl.formatMessage({ id: "field.required" })}
                            />
                          </Column>
                          <Column lg={{ span: 4, offset: 0 }}>
                            {collection.length <= 20 && (
                              <Button
                              iconDescription={ props.intl.formatMessage({ id: "button.addmore" }) }
                              size={'small'}
                              className={'more-button'}
                              onClick={addItem}
                              kind={'primary'}
                              renderIcon={Add16}
                              >
                                { props.intl.formatMessage({ id: "button.addmore_short" }) }
                              </Button>
                            )}
                            {collection.length > 1 && (
                              <Button
                                iconDescription={ props.intl.formatMessage({ id: "button.remove" }) }
                                size={'small'}
                                className={'delete-button'}
                                onClick={() => removeItem(id)}
                                kind={'tertiary'}
                                renderIcon={Delete16}
                              >
                                { props.intl.formatMessage({ id: "button.remove" }) }
                              </Button>
                            )}
                          </Column>
                      </Row>
                    </div>
                  ))}
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="visit.step6"
          label={ props.intl.formatMessage({ id: "form.steps.review" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <Review form='visit' data={ formData } />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
            /* @ts-ignore */
            <FormizStep
              name="visit.step7"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <h4 className="subheading">
                        { props.intl.formatMessage({ id: "thankyou" }) }
                      </h4>
                      <p>
                        {
                          props.intl.formatMessage({ id: "thankyou_content" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        <Footer form='visit' currentForm={qfForm} currLang={props.currLang} data={ formData } />
      </form>
    </Formiz>
  )
}

export default injectIntl(VisitWizard);
