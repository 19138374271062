import React, { useState } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core'
import Review from '../Review'
import SelectField, { InputField, TextAreaField, TileField } from '../Fields'
import { ProgressIndicator, ProgressStep, Grid, Row, Column, FormLabel } from "carbon-components-react";
import { isEmail, isNumber, isMaxLength } from '@formiz/validations'
import { COUNTRIES_EN, COUNTRIES_AR } from '../../data/Countries'
import { injectIntl, FormattedMessage } from 'react-intl';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';

/* @ts-ignore */
const InterviewWizard = (props: { currLang: any, intl: any }) => {
  const qfForm = useForm()
  const [prefLanguage, setPrefLanguage] = useState(true);
  var collection: any = [];

  var radioboxes = []
  var allRadioboxes = document.querySelectorAll('input[type=radio]:checked')

  for (var n = 0; n < allRadioboxes.length; n++) {
    /* @ts-ignore */
    radioboxes.push({[allRadioboxes[n].name]: allRadioboxes[n].value})
  }

  var step1 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step1')).sort()
          .map(([_, v]) => v)

  var step2 = Object.entries(qfForm.values)
          .filter(([k]) => k.startsWith('step2')).sort()
          .map(([_, v]) => v)

  // @ts-ignore
  var selected_country = step1[0] && step1[0]['6_country']
  // @ts-ignore
  step1[0] && delete step1[0]['6_country']

  // @ts-ignore
  step2[0] && delete step2[0]['0_english'] && delete step2[0]['0_arabic']

  var checkedValue = []; 
  var inputElements = document.getElementsByClassName('bx--tile-input');
  for(var i=0; inputElements[i]; ++i){
      /* @ts-ignore */
      if(inputElements[i].checked) {
          /* @ts-ignore */
          checkedValue.push(inputElements[i].value);
      }
  }

  // @ts-ignore
  step1 = step1[0]
  // @ts-ignore
  step2 = step2[0]

  // @ts-ignore
  step1 = {...step1, ['6_country']: selected_country };
  // @ts-ignore
  step2 = {...step2, ['0_preferred_language']: checkedValue.join(', ')};

  let formData = {
    step1,
    step2
  }

  /* @ts-ignore */
  const switchStep = (element, step) => {
    element.preventDefault();
    qfForm.goToStep(step.name)
  }

  const preferredLanguage = () => {
    var checkedValue = null; 
    var inputElements = document.getElementsByClassName('bx--tile-input');
    for(var i=0; inputElements[i]; ++i){
        /* @ts-ignore */
        if(inputElements[i].checked) {
            /* @ts-ignore */
            checkedValue = inputElements[i].value;
            break;
        }
    }
    if(checkedValue) {
      setPrefLanguage(false)
    } else {
      setPrefLanguage(true)
    }
  };

  return (
    <Formiz
      connect={qfForm}
      onValidSubmit={null}
    >
      <Header currentForm={qfForm} currLang={props.currLang} />
      <form
        noValidate
        onSubmit={qfForm.submitStep}
        className={'wizard'}
      >
        <Grid>
          {
            !qfForm.isLastStep && (
              <Row>
              <Column>
                <FormLabel>
                  <h2>
                    { props.intl.formatMessage({ id: "interview.title" }) }
                  </h2>
                </FormLabel>
              </Column>
            </Row>
            )
          }
          {!qfForm.isFirstStep && !qfForm.isLastStep && (
          <Row>
            <Column lg={{ span: 12, offset: 0 }}>
              {
                /* @ts-ignore */
                <ProgressIndicator spaceEqually={true}>
                  <div className="form__tabs">
                  {
                    /* @ts-ignore */
                    qfForm.steps.map((step, i) => (
                      i !== 0 && i !== (qfForm.steps.length - 1) && (
                        /* @ts-ignore */
                        <ProgressStep
                          onClick={(element) => switchStep(element, step)} 
                          label={step.label} 
                          key={step.name}
                          /* @ts-ignore */
                          current={step.name === qfForm.currentStep.name ? true: false} 
                          complete={step.isValid && step.isSubmitted ? true: false} 
                          disabled={ i === (qfForm.steps.length - 2) && !qfForm.isValid ? true : false }
                        />
                      )
                    ))
                    }
                  </div>
                </ProgressIndicator>
              }
            </Column>
          </Row>
          )}
          </Grid>
          {
            /* @ts-ignore */
            <FormizStep
              name="interview.step0"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <p>
                        {
                          <FormattedMessage
                                id="interview.intro_text"
                                values={{
                                    link: <a target='_blank' className='color_white' href={props.currLang === "ar"  ? "https://www.qf.org.qa/ar/media-center/experts" : "https://www.qf.org.qa/media-center/experts"}>
                                        <FormattedMessage
                                            id="here"
                                        />
                                    </a>
                                }}
                            />
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
            name="interview.step1"
            label={ props.intl.formatMessage({ id: "form.steps.details" }) }
          >
            <Grid className='margin-md form'>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.1_name"
                  label={ props.intl.formatMessage({ id: "field.name" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.2_mobile"
                  label={ props.intl.formatMessage({ id: "field.mobile" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isNumber(),
                      message: props.intl.formatMessage({ id: "validation.mobile" }),
                    },
                    {
                      rule: isMaxLength(12),
                      message: props.intl.formatMessage({ id: "validation.mobile.maxlength" }),
                    }
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.3_title"
                  label={ props.intl.formatMessage({ id: "field.title" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.4_email"
                  label={ props.intl.formatMessage({ id: "field.email" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                  validations={[
                    {
                      rule: isEmail(),
                      message: props.intl.formatMessage({ id: "validation.email" }),
                    },
                  ]}
                />
                </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step1.5_company"
                  label={ props.intl.formatMessage({ id: "field.company" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
                <Column lg={{ span: 4, offset: 0 }}>
                <SelectField
                  name="step1.6_country"
                  label={ props.intl.formatMessage({ id: "field.country" }) }
                  required={props.intl.formatMessage({ id: "select.required" })}
                  options={props.currLang === "ar" ? COUNTRIES_AR : COUNTRIES_EN}
                />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="interview.step2"
          label={ props.intl.formatMessage({ id: "interview.step2" }) }
          >
            <Grid>
              <Row>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_english`}
                      id="tile_english"
                      label={ props.intl.formatMessage({ id: "field.english" }) }
                      title={props.intl.formatMessage({ id: "field.preferred_language" })}
                      required={prefLanguage && props.intl.formatMessage({ id: "field.required" })}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
                  <Column lg={{ span: 4, offset: 0 }}>
                    <TileField
                      name={`step2.0_arabic`}
                      id="tile_arabic"
                      label={ props.intl.formatMessage({ id: "field.arabic" }) }
                      className={'tile'}
                      /* @ts-ignore */
                      onChange={e => preferredLanguage()}
                    />
                  </Column>
              </Row>
              <Row>
                <Column lg={{ span: 4, offset: 0 }}>
                <InputField
                  name="step2.1_interview_topic"
                  label={ props.intl.formatMessage({ id: "field.interview_topic" }) }
                  required={props.intl.formatMessage({ id: "field.required" })}
                />
                </Column>
              </Row>
              <Row>
                  <Column lg={{ span: 8, offset: 0 }}>
                    <TextAreaField
                      name={`step2.2_additional_details`}
                      label={ props.intl.formatMessage({ id: "field.additional_details" }) }
                      required={props.intl.formatMessage({ id: "field.required" })}
                    />
                  </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
          /* @ts-ignore */
          <FormizStep
          name="interview.step3"
          label={ props.intl.formatMessage({ id: "form.steps.review" }) }
          >
            <Grid>
              <Row>
                <Column lg={{ span: 12, offset: 0 }}>
                  <Review form='interview' data={ formData } />
                </Column>
              </Row>
            </Grid>
          </FormizStep>
        }
        {
            /* @ts-ignore */
            <FormizStep
              name="interview.step4"
            >
              <Grid className='margin-md intro-section'>
                <Row>
                    <Column lg={{ span: 6, offset: 0 }}>
                      <h4 className="subheading">
                        { props.intl.formatMessage({ id: "thankyou" }) }
                      </h4>
                      <p>
                        {
                          props.intl.formatMessage({ id: "thankyou_content" })
                        }
                      </p>
                    </Column>
                </Row>
              </Grid>
            </FormizStep>
        }
        <Footer form='interview' currentForm={qfForm} currLang={props.currLang} data={ formData } />
      </form>
    </Formiz>
  )
}

export default injectIntl(InterviewWizard);